<template>
  <ul class="password-rules">
    <li
      v-for="rule in rules.filter(r => r.title)"
      :key="`password-rule-${rule.id}`"
      class="password-rules__item"
      :class="`password-rules__item--${isRuleValid(rule.id) ? 'valid': 'invalid'}`"
    >
      <div class="password-rules__item__icon">
        <SvgIcon
          :name="isRuleValid(rule.id) ? 'checkmark-1': 'dot'"
          size="m"
          class="password-rules__item__icon__image"
        />
      </div>

      <div class="password-rules__item__title title title--caption">
        {{ rule.title }}
      </div>
    </li>
  </ul>
</template>

<script>
export default {

  props: {
    value: { type: String, default: '' } // v-model
  },

  data: () => ({
    rules: [
      {
        id: 'nospace',
        test: str => str.match(/^\S+$/gi),
        title: null
      },
      {
        id: 'length',
        test: str => str.match(/.{6,}/gi),
        title: 'A jelszó minimum 6 karaktert kell, hogy tartalmazzon'
      },
      {
        id: 'specials',
        test: str => str.match(/[*.!@$%^&(){}[\]:;<>,.?/~_+\-=|]+/gi),
        title: 'A jelszónak speciális karaktert is tartalmaznia kell'
      },
      {
        id: 'capitals',
        test: str => str.match(/[a-z]+/g) && str.match(/[A-Z]+/g),
        title: 'A jelszónak kis és nagybetűt is tartalmaznia kell'
      }
    ]
  }),

  computed: {
    password: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },

    rulesValidity () {
      return Object.fromEntries(this.rules.map(rule => [rule.id, !!rule.test(this.password)]))
    }
  },

  watch: {
    rulesValidity: {
      deep: true,
      immediate: true,
      handler (value) { this.$emit('rules-validity-update', Object.values(value).every(Boolean)) }
    }
  },

  methods: {
    isRuleValid (ruleId) {
      return this.rulesValidity?.[ruleId]
    }
  }

}
</script>
