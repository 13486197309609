<template>
  <div class="user-account-form">
    <div class="user-account-form__primary">
      <div v-if="message" class="modal__message message">
        {{ message }}
      </div>

      <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }" tag="div">
        <form @submit.prevent="handleSubmit(login)">
          <div class="form">
            <ValidationProvider v-slot="{ errors, classes }" vid="email" name="E-mail cím" rules="required|email" slim>
              <BaseInput
                v-model="form.email"
                label="E-mail cím"
                type="email"
                autocomplete="email"
                class="form__input"
                :input-class="classes"
                :errors="errors"
                data-cy="login-modal.email-input"
              />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors, classes }" vid="password" name="Jelszó" rules="required" slim>
              <PasswordInput
                v-model="form.password"
                label="Jelszó"
                autocomplete="current-password"
                class="form__input"
                :input-class="classes"
                :errors="errors"
                data-cy="login-modal.current-password-input"
              />
            </ValidationProvider>
          </div>
          <p class="user-account-form__cta modal__cta">
            <BaseButton type="submit" size="large" class="modal__cta__button button--flush" data-cy="login-modal.submit-button">
              Bejelentkezés
            </BaseButton>
          </p>
        </form>
      </ValidationObserver>

      <p class="user-account-form__sub-cta">
        <BaseButton size="small" data-cy="login-modal.forgot-password-button" @click="openForgottenPasswordModal">
          Elfelejtettem a jelszavam
        </BaseButton>
      </p>
    </div>

    <div class="user-account-form__separator">
      <hr class="user-account-form__separator__horizontal separator separator--smaller separator--or separator--horizontal">
      <hr class="user-account-form__separator__vertical separator separator--smaller separator--or separator--vertical">
    </div>

    <div class="user-account-form__secondary">
      <div class="user-account-form__secondary__main">
        <!-- Socials: -->
        <div>
          <p class="user-account-form__social">
            <BaseButton
              tag="a"
              :href="facebookLoginUrl"
              class="button--flush button--facebook"
              icon="social-facebook"
              icon-position="left"
              data-cy="login-modal.facebook-login-button"
            >
              <span class="user-account-form__social__verb">Belépés</span> Facebook fiókkal
            </BaseButton>
          </p>
          <p class="user-account-form__social">
            <BaseButton
              tag="a"
              :href="googleLoginUrl"
              class="button--flush button--google"
              icon="social-google"
              icon-position="left"
              data-cy="login-modal.google-login-button"
            >
              <span class="user-account-form__social__verb">Belépés</span> Google fiókkal
            </BaseButton>
          </p>
        </div>
      </div>

      <div class="user-account-form__secondary__footer">
        <!-- Registration: -->
        <div class="user-account-form__footer">
          <p class="user-account-form__footer__title title title--caption">
            Még nem regisztráltál?
          </p>
          <p>
            <BaseButton size="small" data-cy="login-modal.registration-button" @click="openRegistrationModal">
              Regisztráció
            </BaseButton>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessage } from '../utils/messages'

export default {

  emits: ['success'],

  data: () => ({
    message: null,
    form: { email: '', password: '' },
    isLoading: false
  }),

  computed: {
    facebookLoginUrl () { return `${this.$config.CRM_API_URL}/login/facebook?site_id=${this.$config.SITE_ID}` },
    googleLoginUrl () { return `${this.$config.CRM_API_URL}/login/google?site_id=${this.$config.SITE_ID}` }
  },

  created () {
    this.$bus.$on('LOGIN_FORM.SET_MESSAGE', (payload) => { this.message = getMessage(payload || '') })
    this.$bus.$on('LOGIN_FORM.SET_EMAIL', (payload) => { this.form.email = payload || '' })
    this.$bus.$on('LOGIN_FORM.CLEAR_PASSWORD', () => { this.form.password = '' })

    this.$bus.$emit('LOGIN_FORM.CLEAR_PASSWORD')
  },

  methods: {
    async login () {
      this.isLoading = true

      try {
        await this.$auth.loginWith('laravelSanctum', { data: this.form })
        this.$emit('success')
      } catch (error) {
        const message = error?.response?.data?.message || null

        switch (message) {
          case 'login.failed.not-verified':
            this.$refs.loginForm.setErrors({ email: [getMessage('login.failed.not-verified')] })
            break

          case 'login.failed':
            this.$refs.loginForm.setErrors({ password: [getMessage('login.failed')] })
            break

          default:
            this.$refs.loginForm.setErrors({ email: [getMessage('login.failed.unknown-reason')] })
            break
        }
      }

      this.isLoading = false
    },

    openForgottenPasswordModal () {
      this.$bus.$emit('FORGOTTEN_PASSWORD_MODAL.OPEN', { email: this.form.email || '' })
    },

    openRegistrationModal () {
      this.$bus.$emit('REGISTRATION_MODAL.OPEN')
    }
  }

}
</script>
