<template>
  <component
    :is="tag"
    :to="to"
    class="nav-link"
    :class="[{ 'hover': isOpened }, `nav-link--icon-on-${iconPosition}`]"
    @click="$emit('click', $event)"
  >
    <div class="nav-link__inner" @mouseenter="$emit('mouse-enter-inner')">
      <slot name="icon" :icon="icon" :icon-class="iconClass">
        <SvgIcon v-if="icon" :name="icon" size="l" class="nav-link__icon" :class="iconClass" />
      </slot>
      <span v-if="title && !hideTitle" class="nav-link__title" :class="titleClass" :data-text="title">
        <b class="nav-link__title__inner">{{ title }}</b>
      </span>
    </div>
  </component>
</template>

<script>
const AVAILABLE_TAGS = ['button', 'nuxt-link']
const AVAILABLE_ICON_POSITIONS = ['left', 'right']

export default {

  props: {
    tag: { type: String, default: 'nuxt-link', validator: value => AVAILABLE_TAGS.includes(value) },
    iconPosition: { type: String, default: 'left', validator: value => AVAILABLE_ICON_POSITIONS.includes(value) },
    to: { type: String, default: '' },
    icon: { type: String, default: null },
    title: { type: String, default: null },
    isOpened: { type: Boolean, default: false },
    hideTitle: { type: Boolean, default: false },
    iconClass: { type: String, default: '' },
    titleClass: { type: String, default: '' }
  }

}
</script>
