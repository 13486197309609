import fitty from 'fitty'
import { getRootFontSize } from '../library/utils/dom'

const bind = (el, binding) => {
  el.__fit = () => {
    const options = binding?.value || {}
    const baseFontSizeRatio = getRootFontSize()
    const fontSizePxProperty = parseInt((getComputedStyle(el).getPropertyValue('--font-size-px') || '').trim(), 10)

    if (options.maxSize || fontSizePxProperty) {
      options.maxSize = (options.maxSize || fontSizePxProperty) / 16 * baseFontSizeRatio
    }

    el.__fitty = fitty(el, options)
    setTimeout(el.__fitty.fit, 1)
  }

  el.__fit()

  window.addEventListener('resize', el.__fit, false)
  window.addEventListener('orientationchange', el.__fit, false)
}

const update = (el, binding) => {
  el.__fit()
}

const unbind = (el, binding) => {
  el.__fitty.unsubscribe()
  el.__fit = () => {}

  window.removeEventListener('resize', el.__fit, false)
  window.removeEventListener('orientationchange', el.__fit, false)
}

export default {
  bind,
  inserted: update,
  componentUpdated: update,
  update,
  unbind
}
