<template>
  <div class="main-header__navbar">
    <div class="main-header__navbar__top">
      <Hamburger
        class="main-header__navbar__hamburger"
        :is-header-opened="true"
        @click="toggleHeader"
      />
    </div>
    <HorizontalScroller class="main-header__navbar__scroller" :is-silent="true">
      <div class="main-header__navbar__wrapper">
        <div class="main-header__navbar__container container">
          <nav class="main-header__navbar__inner">
            <div
              v-for="(item, i) in items"
              :key="`header-navbar-item-${item.id}`"
              class="main-header__navitem"
              :style="`--transition-offset: ${spread[i]};`"
            >
              <component
                :is="item.isExternal ? 'a' : 'nuxt-link'"
                v-bind="getItemAttrs(item)"
                :href="item.url"
                class="main-header__navitem__link"
                :class="`main-header__navitem__link--${item.id}`"
                @click.native="$parent.handleContentClick"
              >
                <div class="main-header__navitem__title heading heading--1-bold">
                  {{ item.title }}
                </div>
                <div class="main-header__navitem__description">
                  {{ item.description }}
                </div>
              </component>
            </div>
            <div class="main-header__navbar__inner__spacer" aria-hidden="true" />
          </nav>
        </div>
      </div>
    </HorizontalScroller>
  </div>
</template>

<script>
import Hamburger from './Hamburger.vue'

export default {

  components: {
    Hamburger
  },

  data () {
    return {
      items: [
        { id: 'news', title: 'Hírfolyam', description: 'Képbe hozunk a magyar könnyűzenével.', url: '/hirek' },
        { id: 'episodes', title: 'Kontent', description: 'Ez a mi dallamvilágunk: ismerd meg könnyűzenei és kulturális műsorainkat!', isExternal: true, url: this.$config.PUBLIC_SITE_KONTENT_URL },
        { id: 'events', title: 'Események', description: 'Mutatjuk, mi lesz a jövő zenéje – programajánlók, kiállítások, koncertek.', url: '/esemenyek' },
        { id: 'performers', title: 'Előadók', description: 'Hangok, melyektől visszhangzik a magyar könnyűzenei szcéna.', url: '/eloadok' },
        { id: 'program-offices', title: 'Támogatások', description: 'Ha kell egy kis lendület: mentorprogramok és pályázati lehetőségek.', url: '/tamogatasok' }
      ]
    }
  },

  computed: {
    isHeaderOpened () {
      return this.$parent.isHeaderOpened
    },

    spread () {
      const cap = 100
      const step = cap / (this.items || []).length

      return (this.items || [])
        .map((_, i) => (i + 1) * step - (cap / 2) - (step / 2))
        .map(v => Math.round(v))
    }
  },

  methods: {
    getItemAttrs (item) {
      return item.isExternal ? { href: item.url, target: '_blank' } : { to: item.url }
    },

    toggleHeader (...args) {
      return this.$parent.toggleHeader(...args)
    },

    openHeader (...args) {
      return this.$parent.openHeader(...args)
    },

    closeHeader (...args) {
      return this.$parent.closeHeader(...args)
    }
  }

}
</script>
