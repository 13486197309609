import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2cd59a8d = () => interopDefault(import('../routes/Profile/Index.vue' /* webpackChunkName: "" */))
const _7b32831f = () => interopDefault(import('../routes/Profile/Edit.vue' /* webpackChunkName: "" */))
const _0204d029 = () => interopDefault(import('../pages/eloadok/index.vue' /* webpackChunkName: "pages/eloadok/index" */))
const _56b49ec2 = () => interopDefault(import('../pages/esemenyek/index.vue' /* webpackChunkName: "pages/esemenyek/index" */))
const _4e7eb7f9 = () => interopDefault(import('../pages/hirek/index.vue' /* webpackChunkName: "pages/hirek/index" */))
const _1e8bd5f3 = () => interopDefault(import('../pages/kereses.vue' /* webpackChunkName: "pages/kereses" */))
const _02db600a = () => interopDefault(import('../pages/tamogatasok/index.vue' /* webpackChunkName: "pages/tamogatasok/index" */))
const _aaf7755e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _004e2ee1 = () => interopDefault(import('../pages/eloadok/_slug.vue' /* webpackChunkName: "pages/eloadok/_slug" */))
const _54fdfd7a = () => interopDefault(import('../pages/esemenyek/_slug.vue' /* webpackChunkName: "pages/esemenyek/_slug" */))
const _4cc816b1 = () => interopDefault(import('../pages/hirek/_slug.vue' /* webpackChunkName: "pages/hirek/_slug" */))
const _0648a29a = () => interopDefault(import('../pages/tamogatasok/_slug.vue' /* webpackChunkName: "pages/tamogatasok/_slug" */))
const _ae64b7ee = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _83fc973e = () => interopDefault(import('../routes/SocialCallback.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/profilom",
    component: _2cd59a8d,
    name: "UserProfileIndex"
  }, {
    path: "/profilom/szerkesztes",
    component: _7b32831f,
    name: "UserProfileEdit"
  }, {
    path: "/eloadok",
    component: _0204d029,
    name: "eloadok"
  }, {
    path: "/esemenyek",
    component: _56b49ec2,
    name: "esemenyek"
  }, {
    path: "/hirek",
    component: _4e7eb7f9,
    name: "hirek"
  }, {
    path: "/kereses",
    component: _1e8bd5f3,
    name: "kereses"
  }, {
    path: "/tamogatasok",
    component: _02db600a,
    name: "tamogatasok"
  }, {
    path: "/",
    component: _aaf7755e,
    name: "index"
  }, {
    path: "/eloadok/:slug",
    component: _004e2ee1,
    name: "eloadok-slug"
  }, {
    path: "/esemenyek/:slug",
    component: _54fdfd7a,
    name: "esemenyek-slug"
  }, {
    path: "/hirek/:slug",
    component: _4cc816b1,
    name: "hirek-slug"
  }, {
    path: "/tamogatasok/:slug",
    component: _0648a29a,
    name: "tamogatasok-slug"
  }, {
    path: "/:slug",
    component: _ae64b7ee,
    name: "slug"
  }, {
    path: "/login/:provider/callback",
    component: _83fc973e,
    name: "SocialCallback"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
