export const META = new Map()
META.set('baseUrl', 'https://hajogyar.hu')
META.set('title', 'hajogyar.hu – az összekapcsolódás jegyében')
META.set('siteName', 'Hajógyár.hu')
META.set('description', 'Popkulturális tartalmak a kortárs hazai könnyűzene és a társművészetek értékteremtő alkotóival.')
META.set('image', 'https://assets.hajogyar.hu/thumbnail.jpg')
META.set('locale', 'hu')
META.set('twitterCard', 'summary_large_image')

export const AVATAR_FILE_MAX_SIZE = 2000 // in kilobytes

export const EPISODE_TYPES = new Map()
EPISODE_TYPES.set('video', ['Videó', 'Videók']) // the first one is the default Media Type
EPISODE_TYPES.set('audio', ['Hang', 'Hangok'])
EPISODE_TYPES.set('article', ['Szöveg', 'Szövegek'])

export const THEME_TITLES = new Map()
THEME_TITLES.set('dark', 'Sötét téma')
THEME_TITLES.set('light', 'Világos téma')

export const TEASER_VIDEO_SIZES = new Set()
const sizes = [400, 600, 800, 1200, 1840, 1920]
sizes.forEach((size) => { TEASER_VIDEO_SIZES.add(size) })

export const VIMEO_REGEX = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|)(\d+)(?:\/|\?)?/i
export const YOUTUBE_REGEX = /(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube(?:-nocookie)?\.com|youtu.be))(?:\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(?:[^<]\S+)?/

export const PARTNER_SITES = new Set()
PARTNER_SITES.add({ logo: 'hangfoglalo', title: 'Hangfoglaló Program', url: 'https://hangfoglalo.hu' })
PARTNER_SITES.add({ logo: 'hots', title: 'HOTS', url: 'https://hotsmusic.hu' })
PARTNER_SITES.add({ logo: 'halmosbela', title: 'Halmos Béla Program', url: 'https://halmosbelaprogram.hu' })
PARTNER_SITES.add({ logo: 'pku', title: 'Petőfi Kulturális Ügynökség', url: 'https://petofiugynokseg.hu' })

export const SITE_SOCIALS = new Set()
SITE_SOCIALS.add({ title: 'Facebook', icon: 'social-facebook', url: 'https://www.facebook.com/hajogyarponthu' })
SITE_SOCIALS.add({ title: 'Instagram', icon: 'social-instagram', url: 'https://www.instagram.com/hajogyar' })
SITE_SOCIALS.add({ title: 'YouTube', icon: 'social-youtube', url: 'https://www.youtube.com/c/HAJ%C3%93GY%C3%81R/featured' })

export const SITE_SPONSORS = new Set()
SITE_SPONSORS.add({
  title: 'Petőfi Kulturális Ügynökség Nonprofit Zrt.',
  description: 'Kiadja a Petőfi Kulturális Ügynökség Nonprofit&nbsp;Zrt.',
  logo: 'pku'
})
SITE_SPONSORS.add({
  title: 'Emberi Erőforrások Minisztériuma',
  description: 'A portál létrehozását támogatta Magyarország Kormánya megbízásából az&nbsp;Emberi Erőforrások Minisztériuma.',
  logo: 'eemi'
})
