<template>
  <Transition name="fade">
    <div v-if="isVisible" class="forgotten-password-modal modal" :data-theme="modalTheme" @click="onClickOutside">
      <div class="forgotten-password-modal__wrapper modal__wrapper scrollable">
        <div v-loading="isLoading" class="forgotten-password-modal__window modal__window">
          <div class="forgotten-password-modal__inner modal__inner" @click.stop>
            <SvgIcon v-if="isFormSent" name="email-sent" size="xxxl" class="forgotten-password-modal__icon modal__icon" />

            <h3 class="forgotten-password-modal__title modal__title title title--3-light">
              {{ isFormSent ? 'Megerősítő link elküldve' : 'Elfelejtett jelszó' }}
            </h3>

            <p class="forgotten-password-modal__subtitle modal__subtitle title title--5-light">
              {{
                isFormSent
                  ? 'A megerősítő linket elküldtük erre az e-mail címre:'
                  : 'Írd be az e-mail címed, amire szeretnéd, hogy megerősítő linket küldjünk!'
              }}
            </p>

            <p v-if="isFormSent" class="forgotten-password-modal__subtitle modal__subtitle title title--5-light">
              {{ form.email }}
            </p>

            <p v-if="isFormSent" class="forgotten-password-modal__cta modal__cta">
              <BaseButton size="large" class="modal__cta__button button--flush" @click="openLoginModal">
                Vissza a bejelentkezéshez
              </BaseButton>
            </p>

            <ValidationObserver v-else ref="forgottenPasswordForm" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(sendForm)">
                <div class="form">
                  <ValidationProvider v-slot="{ errors, classes }" vid="email" name="E-mail cím" rules="required|email" slim>
                    <BaseInput
                      v-model="form.email"
                      label="E-mail cím"
                      type="email"
                      class="form__input"
                      :input-class="classes"
                      :errors="errors"
                    />
                  </ValidationProvider>
                </div>
                <p class="forgotten-password-modal__cta modal__cta">
                  <BaseButton type="submit" size="large" class="modal__cta__button button--flush">
                    Küldés
                  </BaseButton>
                </p>
              </form>
            </ValidationObserver>
          </div>
          <button type="button" class="modal__close-button" @click.prevent="close">
            <SvgIcon name="x" size="m" class="modal__close-button__icon" />
          </button>
        </div>
      </div>

      <Portal to="curtains">
        <Transition name="fade">
          <div v-if="isVisible" class="curtain curtain--modal" />
        </Transition>
      </Portal>
    </div>
  </Transition>
</template>

<script>
import { getMessage } from '../utils/messages'
import ModalMixin from '../mixins/Modal'

export default {

  name: 'ForgottenPasswordModal',

  mixins: [ModalMixin],

  data: () => ({
    form: { email: '' },
    isFormSent: false,
    isLoading: false
  }),

  methods: {
    open (payload) {
      this.superOpen()
      this.form.email = payload?.email || ''
      this.isFormSent = false
      this.isLoading = false
    },

    async sendForm () {
      this.isLoading = true

      try {
        await this.$axios.get('/crm/sanctum/csrf-cookie')
        await this.$axios.post('/crm/forgot-password', this.form)
        this.isFormSent = true
      } catch (error) {
        const message = error?.response?.data?.message || null
        this.$refs.forgottenPasswordForm.setErrors({ email: [getMessage(message)] })
      }

      this.isLoading = false
    },

    openLoginModal () {
      this.$bus.$emit('LOGIN_MODAL.OPEN', { email: this.form?.email })
    }
  }

}
</script>
