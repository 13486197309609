<template>
  <component
    :is="tag"
    v-if="episode && episode.title"
    v-bind="attr"
    class="episode-card"
    :class="{ 'episode-card--rounded': isRounded }"
  >
    <div class="episode-card__inner js-hoverable">
      <div class="episode-card__content">
        <div class="episode-card__title episode-title" data-theme="dark">
          <ShowTitle
            v-if="episode.show"
            size="6"
            class="episode-title__show"
          >
            {{ episode.show.title }}
          </ShowTitle>
          <span class="episode-title__episode title title--6">{{ episode.title }}</span>
        </div>
      </div>
      <LazyImage
        v-if="episode.thumbnail && episode.thumbnail.url"
        :src="$imgproxy(episode.thumbnail.url, { width: imageWidth })"
        :srcset="`${$imgproxy(episode.thumbnail.url, { width: imageWidth, isRetina: true })} 2x`"
        class="episode-card__thumbnail"
        alt=""
      />
      <PreviewVideo
        v-if="episode.teaser_url"
        :is-visible="isHovered"
        :src="episode.teaser_url"
        class="episode-card__video"
      />
    </div>
  </component>
</template>

<script>
import HoverableCard from '../mixins/HoverableCard.js'
import { EPISODE_TYPES } from '~/constants'

export default {

  mixins: [
    HoverableCard
  ],

  props: {
    episode: { type: Object, default: () => ({}) },
    imageWidth: { type: Number, default: 400 },
    isRounded: { type: Boolean, default: false },
    isExternal: { type: Boolean, default: false },
    mediaType: { type: String, default: '' }
  },

  computed: {
    tag () {
      return this.isExternal ? 'a' : 'nuxt-link'
    },

    attr () {
      return this.isExternal
        ? { href: `${this.$config.PUBLIC_SITE_KONTENT_URL}${this.contentUrl}`, target: '_blank' }
        : { to: this.contentUrl }
    },

    contentUrl () {
      if (!this.episode?.show?.slug || !this.episode?.slug) {
        return ''
      }

      const suffix = EPISODE_TYPES.has(this.mediaType) ? `?media_type=${this.mediaType}` : ''
      return `/musorok/${this.episode.show.slug}/${this.episode.slug}${suffix}`
    }
  }

}
</script>
