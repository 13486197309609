import Vue from 'vue'
import baseLocale from 'vee-validate/dist/locale/hu'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import { confirmed, email, max, min, oneOf, required, size } from 'vee-validate/dist/rules'
import url from '../validators/url'
import maxLength from '../validators/max_length'

extend('confirmed', confirmed)
extend('email', email)
extend('max_length', maxLength)
extend('max', max)
extend('min', min)
extend('oneOf', oneOf)
extend('required', required)
extend('size', size)
extend('url', url)

const locale = {
  ...baseLocale,
  messages: {
    ...baseLocale.messages,
    max_length: 'A(z) {_field_} maximum {length} elemet tartalmazhat',
    max: 'A(z) {_field_} maximum {length} karakter hosszúságú lehet',
    min: 'A(z) {_field_} minimum {length} karakter hosszúságú kell legyen',
    url: 'A(z) {_field_} nem egy helyes webcím'
  }
}

localize('hu', locale)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
