<template>
  <span
    v-if="!!Object.keys(genre || {}).length"
    class="label label--genre"
    :style="`--genre-color: var(--color-genre-${genre.slug})`"
  >
    {{ genre.title }}
  </span>
</template>

<script>
export default {

  props: {
    genre: { type: Object, default: () => ({}) }
  }

}
</script>
