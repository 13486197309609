<template>
  <BaseInput
    v-model="inputValue"
    :type="isPasswordVisible ? 'text' : 'password'"
    inputmode="text"
    :label="label"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :input-class="inputClass"
    :errors="errors"
  >
    <template #addon>
      <button type="button" class="input-field__input__addon__button" @click="togglePasswordVisibility">
        <SvgIcon
          :name="isPasswordVisible ? 'password-hidden' : 'password-visible'"
          size="m"
          class="input-field__input__addon__icon"
        />
      </button>
    </template>
  </BaseInput>
</template>

<script>
export default {

  // eslint-disable-next-line vue/require-prop-types
  props: ['value', 'label', 'placeholder', 'autocomplete', 'errors', 'inputClass'],

  data: () => ({
    isPasswordVisible: false
  }),

  computed: {
    inputValue: { // v-model
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  },

  methods: {
    togglePasswordVisibility () {
      this.isPasswordVisible = !this.isPasswordVisible
    }
  }

}
</script>
