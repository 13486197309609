<template>
  <header
    v-click-outside="closeHeader"
    class="main-header"
    role="banner"
    :class="{ 'main-header--opened': isHeaderOpened }"
  >
    <NavBar />
    <MainNav />

    <Portal to="modals">
      <LoginModal />
      <RegistrationModal />
      <ForgottenPasswordModal />
      <ResetPasswordModal />
    </Portal>
  </header>
</template>

<script>
import { disablePageScroll, enablePageScroll, clearQueueScrollLocks } from 'scroll-lock'
import { isTouchDevice } from '../library/utils/dom'
import ForgottenPasswordModal from '../library/components/ForgottenPasswordModal.vue'
import LoginModal from '../library/components/LoginModal.vue'
import RegistrationModal from '../library/components/RegistrationModal.vue'
import ResetPasswordModal from '../library/components/ResetPasswordModal.vue'

import MainNav from './Header/MainNav.vue'
import NavBar from './Header/NavBar.vue'

export default {

  components: {
    ForgottenPasswordModal,
    LoginModal,
    MainNav,
    NavBar,
    RegistrationModal,
    ResetPasswordModal
  },

  props: {
    value: { type: Boolean, default: false } // v-model
  },

  computed: {
    isHeaderOpened: { // v-model
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  },

  watch: {
    isHeaderOpened (value) {
      setTimeout(() => {
        this[value ? '_disablePageScroll' : '_enablePageScroll']()
      }, 100)
    }
  },

  mounted () {
  },

  beforeDestroy () {
  },

  methods: {
    toggleHeader () {
      this.isHeaderOpened ? this.closeHeader() : this.openHeader()
    },

    openHeader () {
      this.isHeaderOpened = true
    },

    closeHeader () {
      this.isHeaderOpened = false
    },

    handleContentClick () {
      this.closeHeader()
    },

    _enablePageScroll () {
      clearQueueScrollLocks()
      if (!isTouchDevice()) {
        enablePageScroll()
      }
    },

    _disablePageScroll () {
      if (!isTouchDevice()) {
        disablePageScroll()
      }
    }
  }

}
</script>
