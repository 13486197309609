<template>
  <BaseInput
    ref="baseInput"
    v-model="inputValue"
    type="text"
    inputmode="search"
    :label="label"
    autocomplete="off"
    :placeholder="placeholder"
    class="search-input"
    :class="{
      'search-input--expanded': shouldBeExpanded,
      'search-input--flush': isFlush,
      'search-input--collapsed': !shouldBeExpanded,
    }"
    :input-class="[inputClass, 'search-input__input']"
    addon-class="search-input__addon"
    :errors="errors"
    @focus="handleFocusChange"
    @blur="handleFocusChange"
  >
    <template #addon>
      <button type="button" class="input-field__input__addon__button search-input__addon__button" @click="focusInput">
        <SvgIcon
          name="search"
          size="m"
          class="input-field__input__addon__icon"
        />
      </button>
    </template>
  </BaseInput>
</template>

<script>
export default {

  // eslint-disable-next-line vue/require-prop-types
  props: ['value', 'label', 'placeholder', 'autocomplete', 'errors', 'inputClass', 'isFlush'],

  data: () => ({
    isFocused: false
  }),

  computed: {
    inputValue: { // v-model
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },

    shouldBeExpanded () {
      return this.isFocused || !this.isInputEmpty || this.isFlush
    },

    isInputEmpty () {
      return !(this.inputValue || '').trim().length
    }
  },

  methods: {
    handleFocusChange (e) {
      this.isFocused = e.type === 'focus'
    },

    focusInput () {
      this.$refs.baseInput.$refs.inputField.focus()
    }
  }

}
</script>
