<template>
  <div class="breadcrumbs" :class="{ 'breadcrumbs--standalone': isStandalone }">
    <HorizontalScroller class="breadcrumbs__scroller" :is-silent="true">
      <div class="breadcrumbs__container container">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <nuxt-link to="/" class="breadcrumbs__inner breadcrumbs__inner--clickable">
              <SvgIcon name="arrow-left" size="m" class="breadcrumbs__inner__icon" />
              <span class="breadcrumbs__inner__title">Vissza a főoldalra</span>
            </nuxt-link>
          </li>

          <template v-for="(crumb, i) in crumbs">
            <li :key="`crumb-separator-${i}`" class="breadcrumbs__item breadcrumbs__item--separator" aria-hidden="true">
              <b />
            </li>

            <li
              :key="`crumb-${i}`"
              class="breadcrumbs__item"
              :class="{ 'breadcrumbs__item--last': i === (crumbs || []).length - 1 }"
            >
              <component
                :is="crumb.path ? 'nuxt-link' : 'span'"
                :to="crumb.path ? { path: crumb.path } : null"
                class="breadcrumbs__inner"
                :class="{ 'breadcrumbs__inner--clickable': crumb.path }"
              >
                <span class="breadcrumbs__inner__title">{{ crumb.title }}</span>
              </component>
            </li>
          </template>
        </ul>
      </div>
    </HorizontalScroller>
  </div>
</template>

<script>
export default {

  props: {
    isStandalone: { type: Boolean, default: false },
    crumbs: { type: Array, default: () => ([]) }
  }

}
</script>
