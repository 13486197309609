<template>
  <div class="content-section" :class="`content-section--${type}`">
    <div class="content-section__header" :class="containerClasses">
      <h1 v-if="$slots && $slots.title" class="content-section__title" :class="titleClass">
        <slot name="title" />
      </h1>

      <div v-if="$slots && $slots.secondary" class="content-section__secondary">
        <slot name="secondary" />
      </div>
    </div>

    <div v-if="$slots && $slots.default" class="content-section__content" :class="contentClass">
      <slot />
    </div>

    <div v-if="$slots && $slots.secondary" class="content-section__footer" :class="containerClasses">
      <div class="content-section__secondary">
        <slot name="secondary" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    type: { type: String, default: 'basic' },
    containerType: { type: String, default: null },
    contentClass: { type: String, default: null },
    titleClass: { type: String, default: null }
  },

  computed: {
    containerClasses () {
      const classes = ['container']
      if (this.containerType) classes.push(`container--${this.containerType}`)
      return classes
    }
  }

}
</script>
