<template>
  <div class="main-header__hamburger">
    <button
      type="button"
      class="main-header__hamburger__link nav-link"
      :class="{ 'main-header__hamburger__link--opened': isHeaderOpened }"
      @click="handleClick"
    >
      <svg
        class="main-header__hamburger__icon icon icon--l"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          class="main-header__hamburger__icon__line main-header__hamburger__icon__line--1"
          points="4 10 20 10 20 8 4 8"
        />
        <polygon
          class="main-header__hamburger__icon__line main-header__hamburger__icon__line--2"
          points="4 16 20 16 20 14 4 14"
        />
      </svg>
      <span class="visually-hidden">Menü</span>
    </button>
  </div>
</template>

<script>
export default {

  props: {
    isHeaderOpened: { type: Boolean, default: false }
  },

  methods: {
    handleClick () {
      this.$emit('click')
    }
  }

}
</script>
