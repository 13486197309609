export * from './library/constants/index'

export const FOOTER_PRIMARY_PAGES = new Set()
FOOTER_PRIMARY_PAGES.add({ title: 'Hírfolyam', url: '/hirek' })
FOOTER_PRIMARY_PAGES.add({ title: 'Események', url: '/esemenyek' })
FOOTER_PRIMARY_PAGES.add({ title: 'Előadók', url: '/eloadok' })
FOOTER_PRIMARY_PAGES.add({ title: 'Támogatások', url: '/tamogatasok' })

export const FOOTER_SECONDARY_PAGES = new Set()
FOOTER_SECONDARY_PAGES.add({ title: 'Adatkezelési tájékoztató', url: '/adatkezelesi-tajekoztato' })
FOOTER_SECONDARY_PAGES.add({ title: 'Impresszum', url: '/impresszum' })
