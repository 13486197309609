import Cookies from 'js-cookie'

const DEFAULT_EXPIRATION_DAYS = 7
const isDevelopment = process.env.APP_VERSION === 'development'

export const getCookie = cookieName =>
  Cookies.get(cookieName)

export const setCookie = (cookieName, cookieValue, expirationDays) =>
  Cookies.set(cookieName, cookieValue, { expires: expirationDays || DEFAULT_EXPIRATION_DAYS, secure: !isDevelopment })

export const normalizeCookieValue = cookieValue =>
  ['true', 'false'].includes(cookieValue) ? cookieValue === 'true' : cookieValue

export const doesCookieExist = cookieName =>
  typeof getCookie(cookieName) !== 'undefined'
