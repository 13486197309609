<template>
  <nuxt-link
    v-if="event"
    :to="contentUrl"
    class="event-card"
    :class="[ `event-card--${size}` ]"
  >
    <div class="event-card__thumbnail" :style="thumbnailCss">
      <SkeletonBlock :is-loading="isLoading" skeleton-class="event-card__thumbnail__image" aspect-ratio="2 / 3">
        <LazyImage
          v-if="event.thumbnail && event.thumbnail.url"
          :src="$imgproxy(event.thumbnail.url, { width: size === 'large' ? 800 : 500 })"
          :srcset="`${$imgproxy(event.thumbnail.url, { width: size === 'large' ? 600 : 400, isRetina: true })} 2x`"
          class="event-card__thumbnail__image"
          alt=""
        />
      </SkeletonBlock>
      <EventDate v-if="event.date_start" :date="event.date_start" class="event-card__thumbnail__date" />
    </div>

    <div class="event-card__content">
      <EventDate v-if="event.date_start" :date="event.date_start" class="event-card__content__date" />
      <div class="event-card__details">
        <div v-if="event.location && event.location.title" class="event-card__location label label--location">
          {{ event.location.title }}
        </div>
        <div
          class="event-card__title title"
          :class="size === 'large' ? 'title--2' : 'title--5'"
        >
          {{ event.title }}
        </div>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import { getFocalPointCssValue } from '../library/utils/images'
import EventDate from './EventDate.vue'

const AVAILABLE_SIZES = ['medium', 'large']

export default {

  components: {
    EventDate
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => AVAILABLE_SIZES.includes(value)
    },
    event: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    contentUrl () {
      return this.event?.slug ? `/esemenyek/${this.event.slug}` : ''
    },

    thumbnailCss () {
      return getFocalPointCssValue(this.event?.thumbnail?.focus)
        ? { '--focal-position': getFocalPointCssValue(this.event.thumbnail.focus) }
        : {}
    }
  }

}
</script>
