/* eslint-disable padded-blocks */

export default ({ $axios, $config }, inject) => {

  /**
  * HOMEPAGE
  */

  inject('getHomepageDetails', async (limit = 10, page = 1) => await $axios.get('api/cms/pages/home'))

  inject('getRandomEpisodes', async (limit = 10) => {
    try {
      const rand = Math.floor(Math.random() * 100)
      return await $axios.get(`api/cms/pages/home/random-episodes/${rand}`)
    } catch (error) {
      return error.response
    }
  })

  /**
  * SHOWS
  */

  inject('getShows', async (per_page = 10, page = 1, limit = null) =>
    await $axios.get('api/cms/resources/shows', { params: { per_page, page, limit } }))

  inject('getShow', async (showSlug = null) => {
    try {
      return await $axios.get(`api/cms/resources/shows/${showSlug}`)
    } catch (error) {
      return error.response
    }
  })

  inject('getShowRelatedShows', async (showSlug = null, per_page = 10, page = 1, limit = null) =>
    await $axios.get(`api/cms/resources/shows/${showSlug}/related`, { params: { per_page, page, limit } }))

  /**
  * EPISODES
  */

  inject('getEpisode', async (showSlug = null, episodeSlug = null) => {
    try {
      return await $axios.get(`api/cms/resources/shows/${showSlug}/episodes/${episodeSlug}`)
    } catch (error) {
      return error.response
    }
  })

  inject('getOtherEpisodes', async (showSlug = null, episodeSlug = null) =>
    await $axios.get(`api/cms/resources/shows/${showSlug}/episodes/${episodeSlug}/episodes`))

  inject('getEpisodesByMediaType', async (mediaType = null, per_page = 10, page = 1, limit = null) =>
    await $axios.get('api/cms/resources/episodes', { params: { media_type: mediaType, per_page, page, limit } }))

  inject('getEpisodeRelatedShows', async (showSlug = null, episodeSlug = null) =>
    await $axios.get(`api/cms/resources/shows/${showSlug}/episodes/${episodeSlug}/related`))

  /**
  * NEWS
  */

  inject('getNews', async (per_page = 10, page = 1, limit = null, filters = {}) =>
    await $axios.get('api/cms/resources/news', {
      params: {
        per_page,
        page,
        limit,
        'filters[news_category_ids]': filters?.categories || [],
        'filters[query]': filters?.query || undefined
      }
    }))

  inject('getNewsCategories', async (per_page = 10, page = 1, limit = null) =>
    await $axios.get('api/cms/resources/news-categories', { params: { per_page, page, limit } }))

  inject('getArticle', async (slug = null) => await $axios.get(`api/cms/resources/news/${slug}`))

  /**
  * EVENTS
  */

  inject('getEvents', async (per_page = 10, page = 1, limit = null, filters = {}) =>
    await $axios.get('api/cms/resources/events', {
      params: {
        per_page,
        page,
        limit,
        'filters[ids]': filters?.ids,
        'filters[date_start]': filters?.date_start,
        'filters[date_end]': filters?.date_end
      }
    }))

  inject('getEvent', async (slug = null) => await $axios.get(`api/cms/resources/events/${slug}`))

  /**
  * REVIEWS
  */

  inject('getReviews', async (per_page = 10, page = 1, limit = null, filters = {}) =>
    await $axios.get('api/cms/resources/reviews', {
      params: {
        per_page,
        page,
        limit,
        'filters[tag_ids]': filters?.tag_ids || [],
        'filters[query]': filters?.query || undefined,
        'filters[date_start]': filters?.date_start,
        'filters[date_end]': filters?.date_end
      }
    }))

  inject('getReview', async (slug = null) => await $axios.get(`api/cms/resources/reviews/${slug}`))

  /**
  * LIFE JOURNEY
  */

  inject('getPetofiLifeJourney', async (per_page = 10, page = 1, limit = null, filters = {}) =>
    await $axios.get('api/cms/resources/life-journey', {
      params: {
        per_page,
        page,
        limit,
        'filters[date_monthday_start]': filters?.date_monthday_start,
        'filters[date_monthday_end]': filters?.date_monthday_end
      }
    }))

  inject('getPetofiLifeJourneyArticle', async (slug = null) => await $axios.get(`api/cms/resources/life-journey/${slug}`))

  /**
  * PETOFI FEED
  */

  inject('getPetofiFeed', async (per_page = 10, page = 1, limit = null, filters = {}) =>
    await $axios.get('api/cms/resources/petofi-feed', {
      params: {
        per_page,
        page,
        limit,
        'filters[date_start]': filters?.date_start,
        'filters[date_end]': filters?.date_end
      }
    }))

  inject('getPetofiFeedEntry', async (slug = null) => await $axios.get(`api/cms/resources/petofi-feed/${slug}`))

  /**
  * LOCATIONS
  */

  inject('getLocations', async (per_page = 10, page = 1, limit = null, filters = {}) =>
    await $axios.get('api/cms/resources/locations', {
      params: {
        per_page,
        page,
        limit,
        'filters[events][date_start]': filters?.date_start,
        'filters[events][date_end]': filters?.date_end
      }
    }))

  inject('getLocation', async (slug = null, filters = {}) => await $axios.get(`api/cms/resources/locations/${slug}`, {
    params: {
      'filters[events][date_start]': filters?.date_start,
      'filters[events][date_end]': filters?.date_end
    }
  }))

  /**
  * PERFORMERS
  */

  inject('getPerformers', async (per_page = 10, page = 1, limit = null) =>
    await $axios.get('api/cms/resources/performers', { params: { per_page, page, limit } }))

  inject('getPerformer', async (slug = null) => await $axios.get(`api/cms/resources/performers/${slug}`))

  /**
  * PROGRAM OFFICES & SPONSORSHIPS
  */

  inject('getProgramOffices', async () => await $axios.get('api/cms/resources/program-offices'))
  inject('getProgramOffice', async (slug = null) => await $axios.get(`api/cms/resources/program-offices/${slug}`))
  inject('getSponsorships', async () => await $axios.get('api/cms/resources/sponsorships'))
  inject('getSponsorship', async (slug = null) => await $axios.get(`api/cms/resources/sponsorships/${slug}`))

  /**
  * CONTENT PAGES
  */

  inject('getContentPage', async (slug = null) => await $axios.get(`api/cms/resources/pages/${slug}`))

  /**
  * TAXONOMY TERMS
  */

  inject('getTaxonomyTerms', async (slug = null, per_page = 10, page = 1, limit = null) =>
    await $axios.get(`api/cms/resources/taxonomies/${slug}/terms`, { params: { per_page, page, limit } }))

  /**
   * GLOBAL SET
   */
  inject('getGlobalSet', async (handle = null) => await $axios.get(`api/cms/resources/globals/${handle}`))
  /**
  * SEARCH
  */

  inject('getSearchResults', async (query = null) =>
    await $axios.get('api/cms/search', { params: { 'filters[query]': query } }))

  inject('getSearchIndex', async () => await $axios.get('api/cms/search/shows'))
  inject('getSearchTags', async () => await $axios.get('api/cms/search/tags'))

  inject('getMeilisearchResults', async (index, query, options = {}) => {
    // https://docs.meilisearch.com/reference/api/search.html#query-parameters
    const body = {
      ...options,
      q: query,
      filter: [
        ...new Array(options?.filter || []),
        `site_ids NOT EXISTS OR site_ids = ${$config.SITE_ID || 0}`
      ].filter(Boolean)
    }
    const config = { headers: $config.MEILISEARCH_KEY && { Authorization: `Bearer ${$config.MEILISEARCH_KEY}` } }
    const { data } = await $axios.post(`${$config.MEILISEARCH_URL}/indexes/${index}/search`, body, config)
    return data
  })

  /**
  * PROFILES
  */

  inject('getProfile', async (profileId) => {
    try {
      const { data } = await $axios.get(`api/profiles/${profileId}`)
      return data
    } catch (error) {
      throw new Error(error)
    }
  })

  /**
  * USER
  */

  // Profile details:

  inject('getUser', async () => {
    try {
      const { data } = await $axios.get('api/user')
      return data
    } catch (error) {
      return error.response
    }
  })

  inject('updateUser', async (form) => {
    try {
      const { data } = await $axios.post('api/user', form)
      return data
    } catch (error) {
      return { ...(error?.response?.data || {}), success: false }
    }
  })

  inject('uploadUserAvatar', async (file) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('collection', 'avatar')

    try {
      const { data } = await $axios.post('api/user/upload-file', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      return data
    } catch (error) {
      return { ...(error?.response?.data || {}), success: false }
    }
  })

  // Favorites:

  inject('getUserFavorites', async () => {
    try {
      const { data } = await $axios.get('api/user/favorites')
      return data
    } catch (error) {
      return error.response
    }
  })

  inject('updateUserFavorite', async (episodeId) => {
    try {
      const { data } = await $axios.post('api/user/favorite', { id: episodeId })
      return data
    } catch (error) {
      return error.response
    }
  })

  // Followers & Followings:

  inject('getUserFollowers', async () => {
    try {
      const { data } = await $axios.get('api/user/followers')
      return data
    } catch (error) {
      return error.response
    }
  })

  inject('getUserFollowings', async () => {
    try {
      const { data } = await $axios.get('api/user/followings')
      return data
    } catch (error) {
      return error.response
    }
  })

  inject('updateUserFollowing', async (profileId) => {
    try {
      const { data } = await $axios.post('api/user/follow', { id: profileId })
      return data
    } catch (error) {
      return error.response
    }
  })

  // Activity:

  inject('addEpisodeToWatchHistory', async (episodeId) => {
    try {
      const request = { name: 'user_action', properties: { attributes: { id: episodeId } }, event: 'visit_episode' }
      const { data } = await $axios.post('api/user/activity', request)
      return data
    } catch (error) {
      return error.response
    }
  })

  inject('getWatchHistory', async (episodeId) => {
    try {
      const { data } = await $axios.get('api/user/watch-history')
      return data
    } catch (error) {
      return error.response
    }
  })

}
