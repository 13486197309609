<template>
  <span
    v-if="!!Object.keys(category || {}).length"
    class="label label--category"
    :style="`--category-color: var(--color-category-${category.slug})`"
  >
    {{ category.title }}
  </span>
</template>

<script>
export default {

  props: {
    category: { type: Object, default: () => ({}) }
  }

}
</script>
