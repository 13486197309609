export const getFocalPointCssValue = focalObj =>
  (typeof focalObj === 'object' && 'x' in focalObj && 'y' in focalObj)
    ? `${focalObj.x}% ${focalObj.y}%`
    : null

export const getImageCaptionForFullscreen = (image) => {
  const caption = (image?.caption || '').trim()

  let source = (image?.source || '').trim()
  if (source) { source = `Forrás: ${source}` }

  return [caption, source].filter(Boolean).join(' &bull; ')
}
