<template>
  <div class="user-account-link">
    <!-- Logged In: -->
    <NavLink
      v-if="$auth.loggedIn"
      to="/profilom"
      :icon="avatarUrl ? null : 'profile'"
      :icon-position="iconPosition"
      :title="firstName"
      :is-opened="isOpened"
      :icon-class="iconClass"
      :title-class="titleClass"
      @mouse-enter-inner="$emit('mouse-enter-inner')"
    >
      <!-- to="/profilom" -->
      <template #icon>
        <LazyImage
          v-if="avatarUrl"
          :src="$imgproxy(avatarUrl, { width: 32, height: 32, resizeMethod: 'fill' })"
          :srcset="`${$imgproxy(avatarUrl, { width: 32, height: 32, resizeMethod: 'fill', isRetina: true })} 2x`"
          :alt="`${firstName} profilképe`"
          class="nav-link__icon nav-link__icon--image icon icon--l"
          :class="iconClass"
        />
      </template>
    </NavLink>

    <!-- Logged Out: -->
    <NavLink
      v-else
      tag="button"
      type="button"
      icon="profile"
      :icon-position="iconPosition"
      data-cy="navigation.login-button"
      title="Bejelentkezés"
      :icon-class="iconClass"
      :title-class="titleClass"
      @mouse-enter-inner="$emit('mouse-enter-inner')"
      @click="openLoginModal"
    />
  </div>
</template>

<script>
import NavLink from './NavLink.vue'

export default {

  components: {
    NavLink
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['isOpened', 'iconPosition', 'iconClass', 'titleClass'],

  computed: {
    firstName () {
      return this.$auth?.user?.first_name
    },

    avatarUrl () {
      return this.$auth?.user?.avatar_url || null
    }
  },

  methods: {
    openLoginModal () {
      this.$bus.$emit('LOGIN_MODAL.OPEN')
    }
  }

}
</script>
