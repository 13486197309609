export default {

  data: () => ({
    isHovered: false
  }),

  mounted () {
    this.__addEventListeners()
  },

  beforeDestroy () {
    this.__removeEventListeners()
  },

  methods: {
    __addEventListeners () {
      document.addEventListener('mousemove', this.__handleMouseMove)
      document.addEventListener('mouseleave', this.__handleMouseLeave)
      window.addEventListener('blur', this.__handleMouseLeave)
    },

    __removeEventListeners () {
      document.removeEventListener('mousemove', this.__handleMouseMove)
      document.removeEventListener('mouseleave', this.__handleMouseLeave)
      window.removeEventListener('blur', this.__handleMouseLeave)
    },

    __getHoverableEl () {
      const HOVERABLE_CSS_CLASS = 'js-hoverable'

      if (!this.$el || !(this.$el instanceof Element)) {
        return
      }

      if (this.$el.classList?.contains(HOVERABLE_CSS_CLASS)) {
        return this.$el
      }

      return this.$el.querySelector(`.${HOVERABLE_CSS_CLASS}`)
    },

    __handleMouseLeave () {
      this.isHovered = false
    },

    __handleMouseMove () {
      const hoverableEl = this.__getHoverableEl()

      if (!hoverableEl?.querySelector) {
        return
      }

      this.isHovered = !!hoverableEl?.querySelector(':hover')
    }
  }

}
