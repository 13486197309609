<template>
  <div
    v-bind="rootAttrs"
    class="input-field"
    :class="{
      'input-field--textarea': isTextarea,
      'input-field--disabled': isDisabled,
      'input-field--has-error': hasError
    }"
  >
    <label
      v-if="label"
      class="input-field__header"
      :for="`input-field-${instanceId}`"
    >
      {{ label }}
    </label>

    <slot :input-attrs="inputAttrs" :instance-id="instanceId">
      <div class="input-field__input">
        <div class="input-field__input__input">
          <textarea
            v-if="isTextarea"
            :id="`input-field-${instanceId}`"
            ref="inputField"
            :key="`input-field-${instanceId}`"
            v-model="inputValue"
            data-theme="light"
            class="input-field__input__field"
            :class="inputClass"
            :placeholder="placeholder"
            v-bind="inputAttrs"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
          />
          <input
            v-else
            :id="`input-field-${instanceId}`"
            ref="inputField"
            :key="`input-field-${instanceId}`"
            v-model="inputValue"
            data-theme="light"
            class="input-field__input__field"
            :class="inputClass"
            :type="type"
            :inputmode="inputmode"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
            v-bind="inputAttrs"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
          >
        </div>
        <div v-if="$slots.addon" class="input-field__input__addon" :class="addonClass">
          <slot name="addon" />
        </div>
      </div>
    </slot>

    <div v-if="hasError" class="input-field__error error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import autosize from 'autosize'

const DISABLED_ATTRS = ['disabled', 'readonly']
const INPUT_ONLY_ATTRS = ['data-cy', ...DISABLED_ATTRS]

export default {

  inheritAttrs: false,

  props: {
    value: { type: [String, Array], default: null }, // v-model
    label: { type: String, default: null },
    type: { type: String, default: 'text' },
    inputmode: { type: String, default: 'text' },
    placeholder: { type: String, default: null },
    autocomplete: { type: String, default: 'off' },
    errors: { type: Array, default: () => ([]) },
    inputClass: { type: [String, Array, Object], default: null },
    addonClass: { type: [String, Array, Object], default: null }
  },

  data: () => ({
    instanceId: Math.random().toString(36).slice(2)
  }),

  computed: {
    inputValue: { // v-model
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },

    rootAttrs () {
      return Object.fromEntries(Object.entries(this.$attrs).filter(([key, val]) => !INPUT_ONLY_ATTRS.includes(key)))
    },

    inputAttrs () {
      return Object.fromEntries(Object.entries(this.$attrs).filter(([key, val]) => INPUT_ONLY_ATTRS.includes(key)))
    },

    isTextarea () {
      return this.type === 'textarea'
    },

    isDisabled () {
      return Object.keys(this.$attrs).some(attr => DISABLED_ATTRS.includes(attr))
    },

    hasError () {
      return !!((this.errors || [])?.length)
    }
  },

  mounted () {
    if (this.isTextarea) {
      autosize(this.$refs?.inputField)
    }
  },

  beforeDestroy () {
    if (this.isTextarea) {
      autosize.destroy(this.$refs?.inputField)
    }
  }

}
</script>
