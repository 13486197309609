import Vue from 'vue'

import CategoryLabel from '../components/CategoryLabel.vue'
import EventCard from '../components/EventCard.vue'
import GenreLabel from '../components/GenreLabel.vue'
import TopOffsetSizer from '../components/TopOffsetSizer.vue'

Vue.component('CategoryLabel', CategoryLabel)
Vue.component('EventCard', EventCard)
Vue.component('GenreLabel', GenreLabel)
Vue.component('TopOffsetSizer', TopOffsetSizer)
