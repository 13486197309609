<template>
  <div
    v-bind="rootAttrs"
    class="checkbox-field"
    :class="[
      { 'checkbox-field--has-error': hasError },
      `checkbox-field--${align}`
    ]"
  >
    <div class="checkbox-field__field">
      <label class="checkbox-field__checkbox" :for="`checkbox-field-${instanceId}`">
        <input
          v-bind="inputAttrs"
          :id="`checkbox-field-${instanceId}`"
          :key="`checkbox-field-${instanceId}`"
          class="checkbox-field__checkbox__input"
          type="checkbox"
          :value="vmodelValue"
          :checked="shouldBeChecked"
          @change="updateInput"
        >
        <label class="checkbox-field__checkbox__box" :for="`checkbox-field-${instanceId}`">
          <svg
            class="checkbox-field__checkbox__tick"
            viewBox="0 0 13 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5L0 6L4 10L13 1.5L11.5 0L4 7L1.5 4.5Z" />
          </svg>
        </label>
      </label>
      <label class="checkbox-field__label" :for="`checkbox-field-${instanceId}`"><slot /></label>
    </div>
    <div v-if="hasError" class="checkbox-field__error error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
const AVAILABLE_ALIGNMENTS = ['left', 'right']
const INPUT_ONLY_ATTRS = ['data-cy']

export default {

  inheritAttrs: false,

  model: { prop: 'vmodelValue', event: 'change' },

  props: {
    vmodelValue: { type: [Boolean, String, Array], default: null },
    errors: { type: Array, default: () => ([]) },
    align: { type: String, default: 'left', validator: v => AVAILABLE_ALIGNMENTS.includes(v) },
    id: { type: String, default: null },
    value: { type: [String, Number], default: null },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false }
  },

  data () {
    return {
      instanceId: this.id || Math.random().toString(36).slice(2)
    }
  },

  computed: {
    shouldBeChecked () {
      if (Array.isArray(this.vmodelValue)) {
        return this.vmodelValue.includes(this.value)
      }
      return this.vmodelValue === this.trueValue
    },

    rootAttrs () {
      return Object.fromEntries(Object.entries(this.$attrs).filter(([key, val]) => !INPUT_ONLY_ATTRS.includes(key)))
    },

    inputAttrs () {
      return Object.fromEntries(Object.entries(this.$attrs).filter(([key, val]) => INPUT_ONLY_ATTRS.includes(key)))
    },

    hasError () {
      return !!((this.errors || [])?.length)
    }
  },

  methods: {
    updateInput (event) {
      const isChecked = event.target.checked

      if (Array.isArray(this.vmodelValue)) {
        const newValue = [...this.vmodelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }

}
</script>
