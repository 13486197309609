<template>
  <div v-if="date" class="event-date">
    <div class="event-date__day title title--3">
      {{ day }}
    </div>
    <div class="event-date__month title title--6">
      {{ month }}
    </div>
  </div>
</template>

<script>
import { isDate } from '../library/utils/date'

export default {

  props: {
    date: {
      type: [Date, String],
      required: true,
      validator: value => isDate(value)
    }
  },

  computed: {
    month () {
      return (this.$formatDate(this.date, { year: 'hidden', month: 'short', day: 'hidden' }) || '')
        .replace(/\.$/g, '')
    },

    day () {
      return this.$formatDate(this.date, { year: 'hidden', month: 'hidden', day: 'numeric' })
    }
  }

}
</script>
