import { disablePageScroll, enablePageScroll, clearQueueScrollLocks } from 'scroll-lock'
import snakeCase from 'just-snake-case'

const SCROLLABLE_EL_SELECTOR = '.modal__wrapper'

export default {

  inject: {
    modalTheme: { default: null }
  },

  props: { isInitiallyVisible: { type: Boolean, default: false } },

  data: () => ({ isVisible: false }),

  mounted () {
    const modalName = snakeCase(this.$options.name).toUpperCase()

    this.$bus.$on(`${modalName}.OPEN`, this.open)
    this.$bus.$on(`${modalName}.CLOSE`, this.close)
    this.$bus.$on('ALL_MODALS.CLOSE', this.close)

    if (this.isInitiallyVisible) { this.open() }
  },

  watch: {
    isVisible: {
      handler (value) {
        setTimeout(() => {
          this[value ? '_disablePageScroll' : '_enablePageScroll']()
        }, 100)
      },
      immediate: true
    }
  },

  beforeDestroy () {
    this._enablePageScroll()
  },

  methods: {
    superClose () {
      this.isVisible = false
    },

    superOpen () {
      this.$bus.$emit('ALL_MODALS.CLOSE')
      this.isVisible = true
    },

    onClickOutside () {
      // do nothing, but make it configurable
    },

    close () {
      this.superClose()
    },

    open () {
      this.superOpen()
    },

    _getScrollableEl () {
      return ('querySelector' in this.$el) && this.$el.querySelector(SCROLLABLE_EL_SELECTOR)
    },

    _enablePageScroll () {
      clearQueueScrollLocks()
      enablePageScroll(this._getScrollableEl())
    },

    _disablePageScroll () {
      disablePageScroll(this._getScrollableEl())
    }
  }

}
