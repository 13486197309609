<template functional>
  <component
    :is="props.href ? 'nuxt-link' : 'span'"
    :to="props.href || null"
    v-bind="data.attrs"
    class="show-title"
    :class="[
      data.staticClass,
      `show-title--${props.size}`,
      {
        'show-title--hoverable': props.href && props.isHoverable,
        'show-title--wrappable': props.isWrappable
      }
    ]"
  >
    <div class="show-title__inner">
      <span class="show-title__wrapper show-title__wrapper--main">
        <span class="show-title__text">
          <slot />
        </span>
      </span>

      <span class="show-title__wrapper show-title__wrapper--copy" aria-hidden="true">
        <span class="show-title__text">
          <slot />
        </span>
      </span>
    </div>
  </component>
</template>

<script>
const AVAILABLE_SIZES = [...new Array(6)].map((_, i) => `${i + 1}`)

export default {

  functional: true,

  props: {
    size: {
      type: String,
      default: '1',
      validator: value => AVAILABLE_SIZES.includes(value)
    },

    href: {
      type: String,
      default: null
    },

    isWrappable: {
      type: Boolean,
      default: false
    },

    isHoverable: {
      type: Boolean,
      default: true
    }
  }

}
</script>
