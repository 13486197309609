import isFastNet from 'isfastnet'

export default (context, inject) => {
  const config = {
    timesToTest: 5,
    threshold: 150,
    image: '//d3iaw2319u1tqd.cloudfront.net/assets/px.gif',
    allowEarlyExit: true,
    verbose: false
  }

  const bandwidthTest = () =>
    new Promise((resolve, reject) => { isFastNet((data) => { resolve(data) }, config) })

  inject('bandwidthTest', bandwidthTest)
}
