<template>
  <div class="error-page">
    <div class="error-page__inner">
      <client-only>
        <div class="error-page__container container container--narrow">
          <h1 v-if="errorCode" class="error-page__code">
            <span class="error-page__code__inner title title--1" :data-text="errorCode">
              {{ errorCode }}
            </span>
          </h1>

          <h2 class="error-page__title title title--3-light">
            Ajjajj! Valami nincs rendben.
          </h2>

          <p class="error-page__description">
            Reméljük a képszakadást nem a hosszúra sikerült tegnapi koncert okozta.
            Sőt, feltételezhetően olyan oldalra tévedtél, ami valójában nem létezik.
          </p>

          <div class="error-page__footer">
            <BaseButton
              icon="go-back-to-start"
              tag="nuxt-link"
              to="/"
              size="large"
              class="error-page__footer__button"
            >
              Irány a főoldal
            </BaseButton>
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    error: { type: Object, default: () => ({}) }
  },

  computed: {
    errorCode () {
      return this.error?.statusCode || 404
    }
  }

}
</script>
