export const isDomElement = el =>
  el &&
  typeof el === 'object' &&
  el instanceof Element &&
  'getBoundingClientRect' in el

export const removeHTMLTags = (string = '') =>
  string.replace(/<[^>]*>/g, '')

export const getRootFontSize = () => // a.k.a. 1rem in pixels
  parseFloat(getComputedStyle(document.documentElement).fontSize || 16)

// Browser with either Touch Events of Pointer Events running on touch-capable device
export const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0))
}

export const nextIdleFrame = (process.client && (window.requestIdleCallback || window.requestAnimationFrame)) || function (cb) { return setTimeout(() => { cb() }, 1) }
