<template>
  <div class="empty-content">
    <SvgIcon
      :name="isSearchType ? 'search-no-result' : 'empty'"
      :size="isSearchType ? 'xxl' : 'm'"
      class="empty-content__icon"
    />

    <strong v-if="title" class="empty-content__title">
      {{ title }}
    </strong>
  </div>
</template>

<script>
const AVAILABLE_TYPES = [null, 'search']

export default {

  props: {
    type: { type: String, default: null, validator: value => AVAILABLE_TYPES.includes(value) },
    title: { type: String, default: null }
  },

  computed: {
    isSearchType () {
      return this.type === 'search'
    }
  }

}
</script>
