var render = function render(_c,_vm){return _c(_vm.props.href ? 'nuxt-link' : 'span',_vm._b({tag:"component",staticClass:"show-title",class:[
    _vm.data.staticClass,
    `show-title--${_vm.props.size}`,
    {
      'show-title--hoverable': _vm.props.href && _vm.props.isHoverable,
      'show-title--wrappable': _vm.props.isWrappable
    }
  ],attrs:{"to":_vm.props.href || null}},'component',_vm.data.attrs,false),[_c('div',{staticClass:"show-title__inner"},[_c('span',{staticClass:"show-title__wrapper show-title__wrapper--main"},[_c('span',{staticClass:"show-title__text"},[_vm._t("default")],2)]),_vm._v(" "),_c('span',{staticClass:"show-title__wrapper show-title__wrapper--copy",attrs:{"aria-hidden":"true"}},[_c('span',{staticClass:"show-title__text"},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }