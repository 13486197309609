import { isDomElement } from '../utils/dom'

const BASE_MOUSE_COORDS = { x: 50, y: 50, width: 0, height: 0, size: 0 }

export default {

  data: () => ({
    mouseCoords: BASE_MOUSE_COORDS
  }),

  mounted () {
    this.$nextTick(() => {
      const { size } = this.__calcSize()
      this.mouseCoords.size = size
    })
  },

  computed: {
    mouseCoordsCssCustomProperties () {
      return Object.fromEntries(Object.keys(this.mouseCoords).map(key => [`--mouse-${key}`, this.mouseCoords?.[key]]))
    }
  },

  methods: {
    setMouseCoords (e) {
      const { offsetX, offsetY } = e
      const { width, height, size } = this.__calcSize()

      const normalize = coord => Math.round(Math.max(0, Math.min(coord, 1)) * 10000) / 100

      const x = normalize(offsetX / width)
      const y = normalize(offsetY / height)

      this.mouseCoords = { x, y, width, height, size }
    },

    resetMouseCoords () {
      this.mouseCoords = BASE_MOUSE_COORDS
    },

    __calcSize () {
      const { width, height } = isDomElement(this.$el) ? this.$el.getBoundingClientRect() : BASE_MOUSE_COORDS
      const size = Math.ceil(Math.max(width, height))

      return { width, height, size }
    }
  }

}
