/**
 *
 * Use it like this to toggle `.loader` class on element when `isLoading` changes:
 *   `<div v-loading="isLoading">`
 *   will become:
 *   `<div class="loader">`
 *
 * You can also toggle additional modifier classes with directive modifiers,
 * eg. `v-loading.mini` will toggle an additional `loader--mini` class.
 *
 */

const update = (el, binding) => {
  const isLoading = binding.value
  const modifiers = binding.modifiers

  el.classList[isLoading ? 'add' : 'remove']('loader')

  Object.keys(modifiers || {}).forEach((modifier) => {
    el.classList[isLoading ? 'add' : 'remove'](`loader--${modifier}`)
  })
}

export default {
  update,
  bind: update
}
