<template>
  <div
    class="lazy-image"
    :class="{ 'lazy-image--loaded': isImageLoaded }"
  >
    <img
      v-bind="$attrs"
      class="lazy-image__image"
      :class="imgClass"
      loading="lazy"
      @click="handleImageClick"
      @load="handleImageLoad"
    >
  </div>
</template>

<script>

export default {

  inheritAttrs: false,

  props: {
    imgClass: { type: String, default: null }
  },

  data: () => ({
    isImageLoaded: false
  }),

  methods: {
    handleImageClick (e) {
      this.$emit('click', e)
    },

    handleImageLoad () {
      this.isImageLoaded = true
    }
  }

}
</script>
