<template>
  <div class="app__bg" />
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {

  mounted () {
    gsap.to(this.$el, {
      scaleY: '.45',
      scrollTrigger: {
        trigger: this.$el,
        start: 'top top',
        end: 'bottom top',
        scrub: 1.5
      }
    })
  }

}
</script>
