<template>
  <svg
    v-if="name"
    class="icon"
    :class="`icon--${size} icon--mobile-${smallSize || size}`"
    viewBox="0 0 24 24"
  >
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script>
import getIconPath from '../utils/getIconPath'

export default {

  name: 'SvgIcon',

  props: {
    name: { type: String, default: null },
    size: { type: String, default: 'm' },
    smallSize: { type: String, default: null }
  },

  computed: {
    iconPath () {
      return getIconPath(this.name)
    }
  }

}
</script>
