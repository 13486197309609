<template>
  <div class="preview-video">
    <transition name="fade">
      <video
        v-if="isVideoShowable"
        class="preview-video__embed"
        :src="matchedVideoUrl"
        muted
        autoplay
        loop
        playsinline
        disablepictureinpicture
        disableremoteplayback
        @play="handleVideoPlay"
        @playing="handleVideoPlay"
        @pause="handleVideoSuspend"
        @suspend="handleVideoSuspend"
        @waiting="handleVideoSuspend"
      />
    </transition>
  </div>
</template>

<script>
import { isDomElement } from '../utils/dom'
import { TEASER_VIDEO_SIZES } from '../constants'

export default {

  props: {
    src: { type: String, default: null },
    sizes: { type: Array, default: () => [...TEASER_VIDEO_SIZES] },
    isVisible: { type: Boolean, default: true }
  },

  data: () => ({
    resizeObserver: null,
    isBandwidthMeasured: false,
    isBandwidthFast: false,
    currentWidth: 300
  }),

  computed: {
    videoUrls () {
      return Object.fromEntries(this.sizes.map(size => [size, (this.src || '').split(/[#?]/)[0].replace(/\.(?=[^.]*$)/, `_${size}.`)]))
    },

    matchedVideoUrl () {
      const devicePixelRatio = this._getDevicePixelRatio()
      const bandwidthMultiplier = this.isBandwidthFast ? 1 : 0.5
      const sortedSizes = [...this.sizes].sort((a, b) => a - b)

      const matchedSize = sortedSizes.find(size => size > this.currentWidth * 0.9 * devicePixelRatio * bandwidthMultiplier) || [...sortedSizes].pop()

      return this.videoUrls?.[matchedSize]
    },

    isVideoShowable () {
      return this.isBandwidthMeasured && this.isVisible
    }
  },

  watch: {
    isVisible (val) {
      if (!val) {
        this.isBandwidthMeasured = false
        return
      }

      this._checkBandwidth()
    }
  },

  mounted () {
    this.$nextTick(() => {
      this._registerResizeObserver()
      this.isVisible && this._checkBandwidth()
    })
  },

  beforeDestroy () {
    this._unregisterResizeObserver()
  },

  methods: {
    handleVideoPlay (e) {
      this.$emit('video-play', e)
    },

    handleVideoSuspend (e) {
      this.$emit('video-suspend', e)
    },

    _getDevicePixelRatio () {
      if (process.client) {
        return window?.devicePixelRatio || 1
      }

      return 1
    },

    _registerResizeObserver () {
      this.resizeObserver = new ResizeObserver(this._setCurrentWidth)
      this.resizeObserver.observe(this.$el)
    },

    _unregisterResizeObserver () {
      this.resizeObserver?.disconnect()
    },

    _setCurrentWidth () {
      this.currentWidth = isDomElement(this.$el) ? this.$el.getBoundingClientRect()?.width : 0
    },

    async _checkBandwidth () {
      this.isBandwidthFast = await this.$bandwidthTest()
      this.isBandwidthMeasured = true
    }
  }

}
</script>
