<template>
  <transition name="fade">
    <div v-if="isVisible" class="cookie-settings">
      <div class="cookie-settings__header">
        <h3 class="cookie-settings__header__title">
          Helló!
        </h3>
        <div class="cookie-settings__header__icon">
          <SvgIcon name="cookie" size="xxl" class="cookie-settings__header__icon__image" />
        </div>
      </div>

      <div class="cookie-settings__content">
        <p class="cookie-settings__content__title">
          Az oldal sütiket használ a jobb felhasználói élmény érdekében.
        </p>
        <p class="cookie-settings__content__description">
          Felhívjuk figyelmét, hogy személyes adatainak bizonyos kezeléséhez nem feltétlenül szükséges az Ön hozzájárulása.
        </p>
      </div>

      <ul class="cookie-settings__options">
        <li
          v-for="option in options"
          :key="`cookie-settings-${option.id}`"
          class="cookie-settings__options__item"
        >
          <span class="cookie-settings__options__title">
            {{ option.title }}
          </span>
          <div class="cookie-settings__options__toggle">
            <RadioToggle v-model="values[option.id]" :disabled="!option.enabled" />
          </div>
        </li>
      </ul>

      <div class="cookie-settings__footer">
        <BaseButton
          icon="checkmark-2"
          class="cookie-settings__footer__button"
          data-cy="cookie-settings.confirm_button"
          @click="saveSettings"
        >
          Rendben
        </BaseButton>
      </div>
    </div>
  </transition>
</template>

<script>
import { getCookie, setCookie, doesCookieExist, normalizeCookieValue } from '../utils/cookies'

const DEFAULT_COOKIE_NAME = 'hajogyar-cookie-consents'
const COOKIE_EXPIRATION_DAYS = 365

export default {

  props: {
    cookieName: { type: String, default: DEFAULT_COOKIE_NAME }
  },

  data: () => ({
    isVisible: false,
    options: [
      { id: 'marketing', title: 'Marketing és analitika', enabled: true },
      { id: 'site', title: 'Kötelező sütik', enabled: false }
    ],
    values: {
      marketing: true,
      site: true
    }
  }),

  mounted () {
    this._getValuesFromCookie()
    this._setVisiblity()
    this._startTrackingIfAllowed()
  },

  methods: {
    _saveValuesToCookie () {
      setCookie(this.cookieName, new URLSearchParams(this.values).toString(), COOKIE_EXPIRATION_DAYS)
    },

    _getValuesFromCookie () {
      if (!doesCookieExist(this.cookieName)) { return }
      const values = {}
      for (const [key, val] of (new URLSearchParams(getCookie(this.cookieName))).entries()) {
        values[key] = normalizeCookieValue(val)
      }
      this.values = values
    },

    _setVisiblity () {
      this.isVisible = !doesCookieExist(this.cookieName)
    },

    saveSettings () {
      this._saveValuesToCookie()
      this._setVisiblity()
      this._startTrackingIfAllowed()
    },

    _startTrackingIfAllowed () {
      if (!doesCookieExist(this.cookieName) || !this.values?.marketing) { return }

      this.$gtm.push({ event: 'grant_consent' })
    }
  }

}
</script>
