export default ({ store, $config, env }) => {
  const log = (title, value) => {
    // eslint-disable-next-line no-console, max-len
    setTimeout(console.log.bind(
      console,
      `%c ${title}: %c ${value || 'N/A'} %c`,
      'background: #0d8ab4; padding: 2px; border-radius: 5px 0 0 5px; color: #fff;',
      `background: ${value ? '#402080' : '#dd3333'}; padding: 2px; border-radius: 0 5px 5px 0; color: #fff;`,
      'background: transparent;'
    ), 0)
  }

  log('App version', env.APP_VERSION)
  log('Built at', env.APP_BUILDTIME)
  log('CMS API URL', $config.CMS_API_URL)
  log('CRM API URL', $config.CRM_API_URL)
  log('ImgProxy URL', $config.IMGPROXY_URL)
  log('Site ID', $config.SITE_ID)
  log('PUBLIC_SITE_HAJOGYAR_URL', $config.PUBLIC_SITE_HAJOGYAR_URL)
  log('PUBLIC_SITE_KONTENT_URL', $config.PUBLIC_SITE_KONTENT_URL)
}
