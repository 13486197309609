<template>
  <div
    class="multi-toggle"
    :class="{ 'multi-toggle--loaded': isLoaded }"
    :style="generatedCss"
  >
    <ul
      ref="toggle"
      class="multi-toggle__inner"
    >
      <li
        v-for="option, i in options"
        :key="getOptionId(option.id || i)"
        class="multi-toggle__option"
        :class="{ 'multi-toggle__option--selected': isSelected(option) }"
      >
        <label
          :for="getOptionId(option.id || i)"
          class="multi-toggle__label js-label"
          :data-id="getOptionId(option.id || i)"
        >
          <input
            :id="getOptionId(option.id || i)"
            v-model="selectedOption"
            :name="getOptionId(option.id || i)"
            type="radio"
            :value="option.id"
            class="multi-toggle__input"
          >
          {{ option.label }}
        </label>
      </li>
      <li
        aria-hidden="true"
        class="multi-toggle__selector"
      />
    </ul>
  </div>
</template>

<script>
import { isDomElement } from '../utils/dom'

export default {

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    id: { type: String, required: true },
    modelValue: { type: [String, Number], default: null },
    options: { type: Array, default: () => ([]) }
  },

  data: () => ({
    coords: null,
    resizeObserver: null,
    isLoaded: false,
    selectedOption: null
  }),

  computed: {
    generatedCss () {
      const coords = this.coords?.[this.selectedOptionIndex] || null

      return `
        --selector-x-offset: ${coords?.offset || 0}px;
        --selector-width: ${coords?.width || 0}px;
      `
    },

    selectedOptionIndex () {
      return this.options?.findIndex(option => this.isSelected(option))
    },

    selectedOptionObject () {
      return this.options?.find(option => this.isSelected(option))
    }
  },

  watch: {
    options: '_reCalculateCoords',
    modelValue: '_syncModelValueToComponent',
    selectedOption: '_syncComponentValueToModel'
  },

  created () {
    this._syncModelValueToComponent()
  },

  mounted () {
    // this.selectedOption = this.options?.[0]?.id

    this.$nextTick(() => {
      this._registerResizeObserver()
      this._reCalculateCoords()
    })

    setTimeout(() => {
      this.isLoaded = true
    }, 0)
  },

  beforeDestroy () {
    this._unregisterResizeObserver()
  },

  methods: {
    _syncModelValueToComponent () {
      this.selectedOption = this.modelValue
    },

    _syncComponentValueToModel () {
      this.$emit('change', this.selectedOption)
    },

    _registerResizeObserver () {
      this.resizeObserver = new ResizeObserver(this._reCalculateCoords)
      this.resizeObserver.observe(this.$refs?.toggle)
    },

    _unregisterResizeObserver () {
      this.resizeObserver.disconnect()
    },

    _reCalculateCoords () {
      const toggleEl = this.$refs?.toggle

      const baseCoords = isDomElement(toggleEl) ? toggleEl.getBoundingClientRect() : {}
      const labelEls = toggleEl?.querySelectorAll('.js-label')

      this.coords = [...labelEls].map((el) => {
        const c = el.getBoundingClientRect()
        return { offset: c.left - baseCoords.left, width: c.width }
      })
    },

    getOptionId (id) {
      return `${this.id}-${id}`
    },

    isSelected (option) {
      return this.selectedOption === option?.id
    }
  }

}
</script>
