const setRootHeightVariable = (value) => {
  document.documentElement.style.setProperty('--window-inner-height', value)
}

const bind = (el, binding) => {
  el.__syncHeight = () => { setRootHeightVariable(`${window.innerHeight}px`) }
  window.addEventListener('resize', el.__syncHeight)
  el.__syncHeight()
}

const update = (el, binding) => {
  el.__syncHeight()
}

const unbind = (el, binding) => {
  window.removeEventListener('resize', el.__syncHeight)
  el.__syncHeight = () => {}
  setRootHeightVariable('')
}

export default {
  bind,
  inserted: update,
  componentUpdated: update,
  update,
  unbind
}
