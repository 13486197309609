import Vue from 'vue'

import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import BaseButton from '../components/BaseButton.vue'
import BaseInput from '../components/BaseInput.vue'
import Breadcrumbs from '../components/Breadcrumbs.vue'
import CheckboxField from '../components/CheckboxField.vue'
import ContentSection from '../components/ContentSection.vue'
import CopyButton from '../components/CopyButton.vue'
import EmptyContent from '../components/EmptyContent.vue'
import EpisodeCard from '../components/EpisodeCard.vue'
import EpisodeList from '../components/EpisodeList.vue'
import HorizontalScroller from '../components/HorizontalScroller.vue'
import SingleImage from '../components/Image/SingleImage.vue'
import LazyImage from '../components/Image/LazyImage.vue'
import MultiToggle from '../components/MultiToggle.vue'
import PasswordInput from '../components/PasswordInput.vue'
import PasswordRules from '../components/PasswordRules.vue'
import PreviewVideo from '../components/PreviewVideo.vue'
import RadioToggle from '../components/RadioToggle.vue'
import SearchInput from '../components/SearchInput.vue'
import ShowTitle from '../components/ShowTitle.vue'
import SkeletonBlock from '../components/SkeletonBlock.vue'
import SvgIcon from '../components/SvgIcon.vue'

Vue.component('BaseButton', BaseButton)
Vue.component('BaseInput', BaseInput)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('CheckboxField', CheckboxField)
Vue.component('CollapseTransition', CollapseTransition)
Vue.component('ContentSection', ContentSection)
Vue.component('CopyButton', CopyButton)
Vue.component('EmptyContent', EmptyContent)
Vue.component('EpisodeCard', EpisodeCard)
Vue.component('EpisodeList', EpisodeList)
Vue.component('HorizontalScroller', HorizontalScroller)
Vue.component('SingleImage', SingleImage)
Vue.component('LazyImage', LazyImage)
Vue.component('MultiToggle', MultiToggle)
Vue.component('PasswordInput', PasswordInput)
Vue.component('PasswordRules', PasswordRules)
Vue.component('PreviewVideo', PreviewVideo)
Vue.component('RadioToggle', RadioToggle)
Vue.component('SearchInput', SearchInput)
Vue.component('ShowTitle', ShowTitle)
Vue.component('SkeletonBlock', SkeletonBlock)
Vue.component('SvgIcon', SvgIcon)
