<template>
  <div
    class="horizontal-scroller"
    :class="{
      'horizontal-scroller--silent': isSilent,
      'horizontal-scroller--dragging': isDragging
    }"
  >
    <div
      class="horizontal-scroller__scroller"
      @mousedown.left="onMouseDown"
      @mousewheel="cancelMomentumTracking"
    >
      <VueHorizontal
        ref="horizontal"
        snap="none"
        scroll
        :button="arePrevNextButtonsNeeded"
        :button-between="false"
        class="horizontal-scroller__scrollable scrollable"
        @scroll="onScroll"
      >
        <div class="horizontal-scroller__inner">
          <slot />
        </div>

        <template v-if="arePrevNextButtonsNeeded" #btn-prev>
          <div
            class="horizontal-scroller__scrollable__button horizontal-scroller__scrollable__button--prev"
            @click="scrollOneStep(-1)"
          >
            <SvgIcon name="arrow-left" class="horizontal-scroller__scrollable__button__icon" />
          </div>
        </template>

        <template v-if="arePrevNextButtonsNeeded" #btn-next>
          <div
            class="horizontal-scroller__scrollable__button horizontal-scroller__scrollable__button--next"
            @click="scrollOneStep(1)"
          >
            <SvgIcon name="arrow-right" class="horizontal-scroller__scrollable__button__icon" />
          </div>
        </template>
      </VueHorizontal>
    </div>

    <HorizontalScrollBar
      v-if="isScrollBarNeeded"
      :class="scrollbarClass"
      :left.sync="left"
      :visible-width="width"
      :full-width="scrollWidth"
    />
  </div>
</template>

<script>
import typeOf from 'just-typeof'
import clamp from 'just-clamp'
import VueHorizontal from 'vue-horizontal'
import { isTouchDevice, nextIdleFrame } from '../../library/utils/dom'
import HorizontalScrollBar from './HorizontalScroll/ScrollBar.vue'

export default {

  components: {
    HorizontalScrollBar,
    VueHorizontal
  },

  props: {
    scrollbarClass: { type: [String, Boolean], default: 'container', validator: value => ((typeOf(value) === 'boolean' && !value) || typeOf(value) !== 'boolean') },
    isSilent: { type: Boolean, default: false }
  },

  data: () => ({
    isDragging: false,
    left: 0,
    width: 0,
    scrollWidth: 0,
    velocityX: 0,
    momentumID: null,
    intervalID: null,
    dragStartX: null,
    originX: 0,
    originLeft: 0
  }),

  computed: {
    isScrollBarNeeded () {
      return !this.isSilent
    },

    arePrevNextButtonsNeeded () {
      return !this.isSilent
    },

    leftBoundaries () {
      return [0, this.scrollWidth - this.width]
    }
  },

  watch: {
    left (val) {
      this.scrollToLeft(val)
    }
  },

  async mounted () {
    await this.$nextTick()
    this._addEventListeners()

    // periodically re-check for content changes
    this.intervalID = setInterval(() => { this._calcWidths() }, 1000)
  },

  beforeDestroy () {
    this._onMouseUp()
    this.cancelMomentumTracking()
    this._removeEventListeners()

    clearInterval(this.intervalID)
    this.isDragging = false
  },

  methods: {
    _addEventListeners () {
      window.addEventListener('resize', this._calcWidths)
      window.addEventListener('orientationchange', this._calcWidths)
    },

    _removeEventListeners () {
      window.removeEventListener('resize', this._calcWidths)
      window.removeEventListener('orientationchange', this._calcWidths)
    },

    _calcWidths () {
      nextIdleFrame(() => {
        const container = this.$refs?.horizontal?.$refs?.container || {}

        this.width = container.offsetWidth
        this.scrollWidth = container.scrollWidth

        this.$refs?.horizontal?.refresh()
      })
    },

    scrollToLeft (val) {
      this.$refs.horizontal.scrollToLeft(val, 'auto')
    },

    onScroll ({ left }) {
      this.left = left
      this._calcWidths()
    },

    scrollOneStep (rate) {
      const step = Math.min(this.width, Math.min(1000, Math.max(150, this.width / 4) + this.width / 2))
      this.smoothScrollLeftTo(this.left + (step * rate))
    },

    smoothScrollLeftTo (destination = this.left) {
      const oldLeft = this.left
      const newLeft = clamp(this.leftBoundaries[0], destination, this.leftBoundaries[1])

      const distance = newLeft - oldLeft

      if (!process.client) {
        this.left = newLeft
        return
      }

      this.$easingAnimationFrames({
        easingType: 'quadOut',
        duration: 200,
        template: ({ progress }) => {
          this.left = oldLeft + (progress * distance)
        },
        complete: () => {
          this.left = newLeft
        }
      })
    },

    onMouseDown (e) {
      if (isTouchDevice()) return

      e.preventDefault() // needed for FF, otherwise it would start the drag interaction
      this.cancelMomentumTracking()
      this.dragStartX = e.pageX
      this.originX = e.pageX
      this.originLeft = this.left
      window.addEventListener('mouseup', this._onMouseUp)
      window.addEventListener('mousemove', this._onMouseMove)
    },

    _onMouseUp () {
      if (isTouchDevice()) return

      this._beginMomentumTracking()
      this.dragStartX = null
      this.isDragging = false
      window.removeEventListener('mouseup', this._onMouseUp)
      window.removeEventListener('mousemove', this._onMouseMove)
    },

    _beginMomentumTracking () {
      this.cancelMomentumTracking()
      this.momentumID = requestAnimationFrame(this._momentumLoop)
    },

    cancelMomentumTracking () {
      cancelAnimationFrame(this.momentumID)
    },

    _momentumLoop () {
      const left = this.left - this.velocityX
      this.velocityX = this.velocityX * 0.95
      this.scrollToLeft(left)

      if (Math.abs(this.velocityX) > 0.5) {
        this.momentumID = requestAnimationFrame(this._momentumLoop)
      }
    },

    _onMouseMove (e) {
      e.preventDefault()

      const walk = (e.pageX - this.originX)

      if (this.dragStartX !== null) {
        const DRAG_THRESHOLD = 10 // in px
        const dragDistance = Math.abs(e.pageX - this.dragStartX)

        if (dragDistance >= DRAG_THRESHOLD) {
          this.isDragging = true
          this.dragStartX = null
        }
      }

      this.originX = this.originX + walk
      this.originLeft = this.left
      this.velocityX = walk * 2 // speed it up a little bit

      this.scrollToLeft(this.left - walk)
    }
  }

}
</script>
