<template>
  <Transition name="fade">
    <div v-if="isVisible" class="user-account-modal modal modal--wide" :data-theme="modalTheme" @click="onClickOutside">
      <div class="user-account-modal__wrapper modal__wrapper scrollable">
        <div v-loading="isLoading" class="user-account-modal__window modal__window">
          <div class="user-account-modal__inner modal__inner" @click.stop>
            <h3 class="user-account-modal__title modal__title title title--3-light">
              Bejelentkezés
            </h3>

            <LoginForm @success="handleLoginSuccess" />
          </div>
          <button type="button" class="modal__close-button" @click.prevent="close">
            <SvgIcon name="x" size="m" class="modal__close-button__icon" />
          </button>
        </div>
      </div>

      <Portal to="curtains">
        <Transition name="fade">
          <div v-if="isVisible" class="curtain curtain--modal" />
        </Transition>
      </Portal>
    </div>
  </Transition>
</template>

<script>
import ModalMixin from '../mixins/Modal'
import LoginForm from './LoginForm.vue'

export default {

  name: 'LoginModal',

  components: {
    LoginForm
  },

  mixins: [ModalMixin],

  data: () => ({
    isLoading: false
  }),

  mounted () {
    this._handlePreOpeningModal()
  },

  methods: {
    open (payload) {
      this.superOpen()

      this.$nextTick(() => {
        this.$bus.$emit('LOGIN_FORM.SET_MESSAGE', payload?.message || '')
        this.$bus.$emit('LOGIN_FORM.SET_EMAIL', payload?.email || '')
        this.$bus.$emit('LOGIN_FORM.CLEAR_PASSWORD')
      })
    },

    handleLoginSuccess () {
      this.close()
    },

    _handlePreOpeningModal () {
      if (this.$route?.query?.showLoginModal === 'true') {
        this.open({
          email: this.$route?.query?.email || '',
          message: this.$route?.query?.message || ''
        })

        // remove query params afterwards:
        this.$router.replace({
          query: {
            ...this.$route?.query,
            showLoginModal: undefined,
            email: undefined,
            message: undefined
          }
        })
      }
    }
  }

}
</script>
