<template>
  <FigureItem
    class="single-image"
    :image="image"
    :show-button="true"
    image-class="single-image__image"
    @image-click="openImageInFullscreen"
  />
</template>

<script>
import lightGallery from 'lightgallery'
import 'lightgallery/css/lightgallery-bundle.min.css'

import { getImageCaptionForFullscreen } from '../../utils/images'
import FigureItem from './FigureItem.vue'

export default {

  components: {
    FigureItem
  },

  props: {
    image: { type: Object, default: () => ({}) }
  },

  data: () => ({
    lightGallery: null
  }),

  computed: {
    imageUrl () {
      return this.image?.url || null
    }
  },

  mounted () {
    this._initGallery()
  },

  methods: {
    openImageInFullscreen () {
      this.lightGallery.openGallery(0)
    },

    async _initGallery () {
      await this.$nextTick()

      this.lightGallery = lightGallery(this.$el, {
        dynamic: true,
        dynamicEl: [{
          src: this.$imgproxy(this.imageUrl, { width: 1600, height: 1200, resizeMethod: 'fill' }),
          subHtml: getImageCaptionForFullscreen(this.image)
        }],
        download: false,
        counter: false,
        licenseKey: this.$config?.LIGHTGALLERY_LICENSE_KEY
      })

      this.$el.addEventListener('lgBeforeOpen', () => {
        document.querySelectorAll('.lg-backdrop').forEach((el) => {
          const imgUrl = this.$imgproxy(this.imageUrl, { width: 600, height: 600, resizeMethod: 'fit' })
          el.style.backgroundImage = `url(${imgUrl})`
        })
      })
    }
  }

}
</script>
