<template>
  <ErrorPage
    class="container container--flush@xs"
    :error="error"
  />
</template>

<script>
import { META } from '../constants'
import { getDefaultHead } from '../library/utils/getMetaTags'
import ErrorPage from '../library/components/ErrorPage.vue'

export default {

  name: 'ErrorLayout',

  components: {
    ErrorPage
  },

  props: {
    error: { type: Object, default: () => ({}) }
  },

  head () {
    return getDefaultHead({
      routePath: this.$route?.path,
      image: this.$imgproxy(META.get('image'), { width: 600 })
    })
  }

}
</script>
