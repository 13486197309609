<template>
  <div
    class="radio-toggle"
    :class="`radio-toggle--${isChecked ? 'on' : 'off'} radio-toggle--${disabled ? 'disabled' : 'enabled'}`"
  >
    <label class="radio-toggle__inner" :for="`radio-toggle-${id}`">

      <input
        :id="`radio-toggle-${id}`"
        :value="value"
        :checked="isChecked"
        class="radio-toggle__input"
        type="checkbox"
        :true-value="trueValue"
        :false-value="falseValue"
        :disabled="disabled"
        @change="handleValueChange"
      >

      <div class="radio-toggle__track">
        <div class="radio-toggle__handle">
          <SvgIcon
            :name="onIcon"
            :size="iconSize"
            class="radio-toggle__handle__icon radio-toggle__handle__icon--on"
          />

          <SvgIcon
            :name="offIcon"
            :size="iconSize"
            class="radio-toggle__handle__icon radio-toggle__handle__icon--off"
          />
        </div>
      </div>

    </label>
  </div>
</template>

<script>
import random from 'string-random'

export default {

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    id: { type: String, default: () => random(32) },
    disabled: { type: Boolean, default: false },
    value: { type: [Boolean, String, Array], default: null },
    trueValue: { type: [Boolean, String], default: true },
    falseValue: { type: [Boolean, String], default: false },
    onIcon: { type: String, default: 'checkmark-1' },
    offIcon: { type: String, default: 'x' },
    iconSize: { type: String, default: 'xs' }
  },

  computed: {
    isChecked () {
      return this.value === this.trueValue
    }
  },

  methods: {
    handleValueChange (event) {
      this.$emit('change', event.target.checked ? this.trueValue : this.falseValue)
    }
  }

}
</script>
