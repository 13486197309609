import { META } from '../constants'

export const getDefaultHead = ({ routePath = null, image = null, hasCanonical = true }) => ({
  link: [
    hasCanonical && routePath && { rel: 'canonical', href: `${META.get('baseUrl')}${routePath}` }
  ].filter(Boolean),
  meta: [
    routePath && { hid: 'og:url', property: 'og:url', content: `${META.get('baseUrl')}${routePath}` },
    routePath && { hid: 'twitter:url', name: 'twitter:url', content: `${META.get('baseUrl')}${routePath}` },
    { hid: 'og:image', property: 'og:image', content: image || META.get('image') },
    { hid: 'twitter:image', name: 'twitter:image', content: image || META.get('image') }
  ].filter(Boolean)
})

export const getArticleTags = tags =>
  tags.map(tag => ({ property: 'article:tag', content: tag }))

export const extractTextFromBlocks = blocks =>
  Array.from(blocks || [])?.find(block => block.type === 'text')?.text || ''

export const getMetaTags = meta => [
  // Generic
  { hid: 'charset', charset: 'utf-8' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  { name: 'format-detection', content: 'telephone=no' },
  { name: 'description', content: meta?.description || META.get('description') },
  { name: 'apple-mobile-web-app-title', content: META.get('siteName') },
  // { name: 'theme-color', content: '' },
  // { name: 'author', content: '' },
  // { name: 'publisher', content: '' },

  // Facebook & LinkedIn
  { property: 'og:locale', content: meta?.locale || META.get('locale') },
  { property: 'og:site_name', content: META.get('siteName') },
  { property: 'og:type', content: meta?.type || 'website' },
  { property: 'og:title', content: meta?.title || META.get('title') },
  { property: 'og:description', content: meta?.description || META.get('description') },
  { property: 'og:image', content: meta?.image || META.get('image') },

  // Twitter
  { name: 'twitter:card', content: meta?.twitterCard || META.get('twitterCard') },
  { name: 'twitter:title', content: meta?.title || META.get('title') },
  { name: 'twitter:description', content: meta?.description || META.get('description') },
  { name: 'twitter:image', content: meta?.image || META.get('image') }
]
  .filter(tag => tag.hid || tag.content)
  .map(tag => ({ ...tag, hid: tag.hid || tag.name || tag.property || null }))

export default getMetaTags
