<template>
  <figure v-if="image && image.url" class="figure-item">
    <div class="figure-item__picture">
      <LazyImage
        :src="$imgproxy(image.url, imgConfig)"
        :srcset="`${$imgproxy(image.url, imgRetinaConfig)} 2x`"
        :alt="image.alt || ''"
        class="figure-item__picture__image"
        img-class="figure-item__picture__image__media"
        :class="imageClass"
        @click="handleImageClick"
      />

      <SvgIcon v-if="showButton" name="full-screen2" size="xl" small-size="l" class="figure-item__picture__button" />
    </div>

    <figcaption v-if="image.caption || image.source" class="figure-item__caption" :class="captionClass">
      {{ image.caption }}

      <span v-if="image.source" class="figure-item__caption__source">
        <span v-if="image.caption" class="figure-item__caption__separator" aria-hidden="true">&bull;</span>
        Forrás: {{ image.source }}
      </span>
    </figcaption>
  </figure>
</template>

<script>
export default {

  props: {
    image: { type: Object, default: () => ({}) },
    imgproxyConfig: { type: Object, default: () => ({}) },
    showButton: { type: Boolean, default: false },
    imageClass: { type: String, default: null },
    captionClass: { type: String, default: null }
  },

  computed: {
    imgConfig () {
      return { width: 1000, height: 1000, resizeMethod: 'fit', gravity: this.image?.focus, ...this.imgproxyConfig }
    },

    imgRetinaConfig () {
      return { ...this.imgConfig, isRetina: true }
    }
  },

  methods: {
    handleImageClick (e) {
      this.$emit('image-click', e)
    }
  }

}
</script>
