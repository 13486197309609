export default ({ req, route, $config }, inject) => {
  const formatCurrency = (value, opts) => {
    if (!value) {
      value = 0
    }

    const options = {
      style: 'currency',
      currency: 'HUF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }

    return new Intl.NumberFormat('hu-HU', options).format(value)
  }

  inject('formatCurrency', formatCurrency)
}
