<template>
  <component
    :is="tag"
    class="button js-hoverable"
    :class="classes"
    :type="type"
    v-bind="$attrs"
    aria-role="button"
    :style="mouseCoordsCssCustomProperties"
    @click="$emit('click', $event)"
    @mouseenter="setMouseCoords"
    @mouseenter.native="setMouseCoords"
  >
    <div class="button__inner">
      <div class="button__content">
        <slot />
      </div>
      <div v-if="icon" class="button__icon" :class="`button__icon--${iconPosition}`">
        <SvgIcon
          :name="icon"
          class="button__icon__image"
          :size="iconSize"
        />
      </div>
      <b class="button__ripple ripple-effect" />
    </div>
  </component>
</template>

<script>
import typeOf from 'just-typeof'

import HoverableCard from '../mixins/HoverableCard'
import MouseCoords from '../mixins/MouseCoords'

const AVAILABLE_TYPES = ['button', 'submit', 'reset']
const AVAILABLE_TAGS = ['button', 'a', 'nuxt-link', 'span', 'label']
const AVAILABLE_SIZES = ['small', 'medium', 'large']
const AVAILABLE_ICON_POSITIONS = ['left', 'right']

export default {

  mixins: [
    HoverableCard,
    MouseCoords
  ],

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'button',
      validator: value => AVAILABLE_TYPES.includes(value)
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => AVAILABLE_SIZES.includes(value)
    },
    tag: {
      type: String,
      default: 'button',
      validator: value => AVAILABLE_TAGS.includes(value)
    },
    icon: {
      type: [String, Boolean],
      default: false,
      validator: value => ((typeOf(value) === 'boolean' && !value) || typeOf(value) !== 'boolean')
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator: value => AVAILABLE_ICON_POSITIONS.includes(value)
    },
    iconSize: {
      type: String,
      default () {
        if (this.size === 'large') return 'l'
        return 'm'
      }
    }
  },

  computed: {
    classes () {
      return {
        'js-ripple-on': this.isHovered,
        [`button--${this.size}`]: this.size
        // 'button--loading': this.loading
      }
    }
  }

}
</script>
