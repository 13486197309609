export default function ({ app, $axios, $config, route, redirect, error: nuxtError }) {
  $axios.onRequest((config) => {
    if (route?.query?.signature && route?.query?.expires) {
      config.params = config.params || {}
      config.params.signature = route?.query?.signature
      config.params.expires = route?.query?.expires
    }

    // console.log(config)
  })

  $axios.onResponse((response) => {
    // console.log(response)
  })

  $axios.onRequest((config) => {
    if (config && config.headers && config.headers.common) {
      config.headers.common['X-Site-Id'] = `${$config.SITE_ID}`
    }
  })
}
