<template>
  <div v-height-sync class="app" :class="{ 'app--header-opened': isHeaderOpened }">
    <AppBackground />
    <MainHeader v-model="isHeaderOpened" class="app__header" />

    <div class="app__content" :class="{ 'app__content--locked': isHeaderOpened }">
      <Nuxt class="main" />
      <MainFooter />
    </div>

    <CookieSettings />
    <PortalTarget name="modals" multiple />
    <PortalTarget name="curtains" multiple />
  </div>
</template>

<script>
import AppBackground from '../components/AppBackground.vue'
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import CookieSettings from '../library/components/CookieSettings.vue'

import { META } from '../constants'
import { getDefaultHead } from '../library/utils/getMetaTags'

export default {

  name: 'DefaultLayout',

  components: {
    AppBackground,
    MainHeader,
    MainFooter,
    CookieSettings
  },

  data: () => ({
    isHeaderOpened: false
  }),

  head () {
    return getDefaultHead({
      routePath: this.$route?.path,
      image: this.$imgproxy(META.get('image'), { width: 600 })
    })
  }

}
</script>
