<template>
  <Transition name="fade">
    <div
      v-if="isVisible"
      class="user-account-modal modal"
      :class="{ 'modal--wide': !isFormSent }"
      :data-theme="modalTheme"
      @click="onClickOutside"
    >
      <div class="user-account-modal__wrapper modal__wrapper scrollable">
        <div v-loading="isLoading" class="user-account-modal__window modal__window">
          <div class="user-account-modal__inner modal__inner" @click.stop>
            <SvgIcon v-if="isFormSent" name="checkmark-2" size="xxxl" class="user-account-modal__icon modal__icon" />

            <h3 class="user-account-modal__title modal__title title title--3-light">
              {{ isFormSent ? 'Sikeres regisztráció' : 'Regisztráció' }}
            </h3>

            <p v-if="isFormSent" class="user-account-modal__subtitle modal__subtitle title title--5-light">
              Köszönjük a regisztrációdat! Kérjük erősítsd meg fiókodat az e-mailben található link segítségével!
            </p>

            <p v-if="isFormSent" class="user-account-modal__cta modal__cta">
              <BaseButton tag="a" href="/" size="large" class="modal__cta__button button--flush">
                Tovább a főoldalra
              </BaseButton>
            </p>

            <div v-else class="user-account-form">
              <ValidationObserver ref="registrationForm" v-slot="{ handleSubmit }" tag="div" class="user-account-form__primary">
                <!-- Form: -->
                <form @submit.prevent="handleSubmit(register)">
                  <div class="user-account-form__form form">
                    <div class="form__group">
                      <ValidationProvider v-slot="{ errors, classes }" vid="last_name" name="Vezetéknév" rules="required" slim>
                        <BaseInput
                          v-model="form.last_name"
                          label="Vezetéknév"
                          type="text"
                          autocomplete="family-name"
                          class="form__input"
                          :input-class="classes"
                          :errors="errors"
                          data-cy="registration-modal.lastname-input"
                        />
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors, classes }" vid="first_name" name="Keresztnév" rules="required" slim>
                        <BaseInput
                          v-model="form.first_name"
                          label="Keresztnév"
                          type="text"
                          autocomplete="given-name"
                          class="form__input"
                          :input-class="classes"
                          :errors="errors"
                          data-cy="registration-modal.firstname-input"
                        />
                      </ValidationProvider>
                    </div>

                    <ValidationProvider v-slot="{ errors, classes }" vid="email" name="E-mail cím" rules="required|email" slim>
                      <BaseInput
                        v-model="form.email"
                        label="E-mail cím"
                        type="email"
                        autocomplete="email"
                        class="form__input"
                        :input-class="classes"
                        :errors="errors"
                        data-cy="registration-modal.email-input"
                      />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors, classes }" vid="password" name="Jelszó" rules="required" slim>
                      <PasswordInput
                        v-model="form.password"
                        label="Jelszó"
                        autocomplete="new-password"
                        class="form__input"
                        :input-class="classes"
                        :errors="errors"
                        data-cy="registration-modal.password-input"
                      />
                    </ValidationProvider>

                    <PasswordRules
                      v-model="form.password"
                      class="user-account-form__form__password-rules form__password-rules"
                      @rules-validity-update="handlePasswordRulesValidityUpdate"
                    />

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="privacy_policy"
                      name="Adatvédelmi nyilatkozat"
                      :rules="{ required: { allowFalse: false } }"
                      slim
                    >
                      <CheckboxField v-model="form.privacy_policy" class="form__input form__input--checkbox" :errors="errors" data-cy="registration-modal.privacypolicy-checkbox">
                        Elfogadom az <a href="/adatvedelmi-nyilatkozat" target="_blank" class="link">adatvédelmi nyilatkozatot</a>.
                      </CheckboxField>
                    </ValidationProvider>

                    <CheckboxField v-model="form.newsletter" class="form__input form__input--checkbox" data-cy="registration-modal.newsletter-checkbox">
                      Feliratkozom a hírlevélre.
                    </CheckboxField>
                  </div>
                  <p class="user-account-form__cta modal__cta">
                    <BaseButton type="submit" size="large" class="modal__cta__button button--flush" data-cy="registration-modal.submit-button">
                      Regisztráció
                    </BaseButton>
                  </p>
                </form>
              </ValidationObserver>

              <div class="user-account-form__separator">
                <hr class="user-account-form__separator__horizontal separator separator--smaller separator--or separator--horizontal">
                <hr class="user-account-form__separator__vertical separator separator--smaller separator--or separator--vertical">
              </div>

              <div class="user-account-form__secondary">
                <div class="user-account-form__secondary__main">
                  <!-- Socials: -->
                  <div>
                    <p class="user-account-form__social">
                      <BaseButton
                        tag="a"
                        :href="facebookLoginUrl"
                        class="button--flush button--facebook"
                        icon="social-facebook"
                        icon-position="left"
                        data-cy="registration-modal.facebook-registration-button"
                      >
                        <span class="user-account-form__social__verb">Regisztráció</span> Facebook fiókkal
                      </BaseButton>
                    </p>
                    <p class="user-account-form__social">
                      <BaseButton
                        tag="a"
                        :href="googleLoginUrl"
                        class="button--flush button--google"
                        icon="social-google"
                        icon-position="left"
                        data-cy="registration-modal.google-registration-button"
                      >
                        <span class="user-account-form__social__verb">Regisztráció</span> Google fiókkal
                      </BaseButton>
                    </p>
                  </div>
                </div>

                <div class="user-account-form__secondary__footer">
                  <!-- Login: -->
                  <div class="user-account-form__footer">
                    <p class="user-account-form__footer__title title title--caption">
                      Már van regisztrációd?
                    </p>
                    <p>
                      <BaseButton size="small" data-cy="registration-modal.login-button" @click="openLoginModal">
                        Bejelentkezés
                      </BaseButton>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="modal__close-button" @click.prevent="close">
            <SvgIcon name="x" size="m" class="modal__close-button__icon" />
          </button>
        </div>
      </div>

      <Portal to="curtains">
        <Transition name="fade">
          <div v-if="isVisible" class="curtain curtain--modal" />
        </Transition>
      </Portal>
    </div>
  </Transition>
</template>

<script>
import ModalMixin from '../mixins/Modal'
import { getMessage } from '../utils/messages'

export default {

  name: 'RegistrationModal',

  mixins: [ModalMixin],

  data: () => ({
    form: { first_name: '', last_name: '', email: '', password: '', privacy_policy: false, newsletter: false },
    isFormSent: false,
    isLoading: false
  }),

  computed: {
    facebookLoginUrl () { return `${this.$config.CRM_API_URL}/login/facebook?site_id=${this.$config.SITE_ID}` },
    googleLoginUrl () { return `${this.$config.CRM_API_URL}/login/google?site_id=${this.$config.SITE_ID}` }
  },

  methods: {
    open (payload) {
      this.superOpen()
      Object.assign(this.form, this.$options.data().form)
      this.form.email = payload?.email || ''
      this.form.password = ''
      this.isFormSent = false
      this.isLoading = false
    },

    async register () {
      this.isLoading = true

      try {
        await this.$axios.get('/crm/sanctum/csrf-cookie')
        await this.$axios.post('/crm/register', this.form)
        this.isFormSent = true
      } catch (error) {
        this.$refs.registrationForm.setErrors(error?.response?.data?.errors || {})
      }

      this.isLoading = false
    },

    handlePasswordRulesValidityUpdate (value) {
      if (!this.form.password || value) {
        this.$refs?.registrationForm?.setErrors({ password: null })
        return
      }

      setTimeout(() => {
        this.$refs?.registrationForm?.setErrors({ password: [getMessage('password.rules.failed')] })
      }, 1)
    },

    openLoginModal () {
      this.$bus.$emit('LOGIN_MODAL.OPEN', { email: this.form.email || null })
    }
  }

}
</script>
