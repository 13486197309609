const validate = (value) => {
  if (!value) {
    return true
  }

  try {
    // eslint-disable-next-line no-new
    new URL(`${value}`)
  } catch {
    return false
  }

  return true
}

export { validate }

export default { validate }
