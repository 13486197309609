<template>
  <ul
    v-if="episodes"
    class="content-list content-list--episodes"
    :class="cssClasses"
  >
    <li
      v-for="episode in episodeList"
      :key="`episode-list-${episode.id || episode.slug || episode.title}`"
      class="content-list__item"
    >
      <SkeletonBlock :is-loading="isLoading" aspect-ratio="17 / 40">
        <EpisodeCard
          :episode="episode"
          :image-width="size === 'large' ? 600 : undefined"
          :is-rounded="listStyle === 'rounded'"
          :is-external="isExternal"
          :media-type="mediaType"
          class="content-list__card"
        />
      </SkeletonBlock>
    </li>
  </ul>
</template>

<script>
const AVAILABLE_SIZES = ['medium', 'large']
const AVAILABLE_STYLES = ['basic', 'rounded']

export default {

  props: {
    isLoading: { type: Boolean, default: false },
    isExternal: { type: Boolean, default: false },
    loadingCount: { type: Number, default: 9 },
    size: { type: String, default: 'medium', validator: value => AVAILABLE_SIZES.includes(value) },
    listStyle: { type: String, default: 'basic', validator: value => AVAILABLE_STYLES.includes(value) },
    episodes: { type: Array, default: () => ([]) },
    mediaType: { type: String, default: '' }
  },

  computed: {
    cssClasses () {
      const classes = [`content-list--${this.size}`]
      if (this.listStyle === 'rounded') classes.push('content-list--guttered')
      return classes
    },

    episodeList () {
      if (this.isLoading) {
        return [...new Array(this.loadingCount)].map((_, i) => ({ id: i + 1 }))
      }

      return this.episodes || []
    }
  }

}
</script>
