<template>
  <div class="main-header__main">
    <div class="main-header__main__container container">
      <nav class="main-header__main__inner">
        <div class="main-header__main__logo">
          <nuxt-link to="/" class="main-header__main__logo__link" @click.native="handleLogoClick">
            <svg class="main-header__main__logo__image" viewBox="0 0 70 56">
              <use xlink:href="/images/logo-emblem.svg#icon" />
            </svg>
          </nuxt-link>
        </div>

        <Hamburger
          class="main-header__main__hamburger"
          :is-header-opened="isHeaderOpened"
          @click="toggleHeader"
        />

        <div class="main-header__main__list">
          <ul class="main-header__main__list__inner">
            <li class="main-header__main__item main-header__main__item--tight-left">
              <NavLink
                to="/kereses"
                icon="search"
                title="Keresés"
                icon-class="main-header__main__item__icon"
                title-class="main-header__main__item__title"
                :hide-title="true"
                @click.native="$parent.handleContentClick"
              />
            </li>
            <li class="main-header__main__item main-header__main__item--tight-left">
              <UserAccountLink
                class="main-header__main__user-link"
                :is-opened="false"
                icon-position="right"
                icon-class="main-header__main__item__icon"
                title-class="main-header__main__item__title"
                @click.native="$parent.handleContentClick"
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import smoothScrollTop from '../../library/utils/smoothScrollTop'

import Hamburger from './Hamburger.vue'
import NavLink from './NavLink.vue'
import UserAccountLink from './UserAccountLink.vue'

export default {

  components: {
    Hamburger,
    NavLink,
    UserAccountLink
  },

  data: () => ({
  }),

  computed: {
    isHeaderOpened () {
      return this.$parent.isHeaderOpened
    }
  },

  methods: {
    handleLogoClick () {
      this.closeHeader()
      smoothScrollTop()
    },

    toggleHeader (...args) {
      return this.$parent.toggleHeader(...args)
    },

    openHeader (...args) {
      return this.$parent.openHeader(...args)
    },

    closeHeader (...args) {
      return this.$parent.closeHeader(...args)
    }
  }

}
</script>
