<template>
  <div class="copy-button">
    <transition name="fade">
      <div v-if="isTooltipShowing" class="copy-button__tooltip">
        Másolva a vágólapra
        <SvgIcon name="checkmark-1" size="xs" class="copy-button__tooltip__icon" />
      </div>
    </transition>
    <BaseButton
      class="copy-button__button js-copy-button"
      :icon="icon"
      :icon-position="iconPosition"
      size="small"
      :data-clipboard-text="text"
    >
      <slot />
    </BaseButton>
  </div>
</template>

<script>
import typeOf from 'just-typeof'
import ClipboardJS from 'clipboard'

export default {

  props: {
    text: { type: String, default: null },
    icon: {
      type: [String, Boolean],
      default: false,
      validator: value => ((typeOf(value) === 'boolean' && !value) || typeOf(value) !== 'boolean')
    },
    iconPosition: String // eslint-disable-line vue/require-default-prop
  },

  data: () => ({
    cb: null,
    isTooltipShowing: false
  }),

  watch: {
    isTooltipShowing (val) {
      if (!val) {
        return
      }

      setTimeout(() => {
        this.isTooltipShowing = false
      }, 1000)
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.cb = new ClipboardJS(this.$el?.querySelector('.js-copy-button'))

      this.cb?.on('success', () => {
        this.isTooltipShowing = true
      })

      // this.cb?.on('error', function (e) {})
    })
  }
}
</script>
