<template>
  <footer class="main-footer">
    <div class="main-footer__separator container">
      <hr class="separator separator--flush">
    </div>

    <ul class="main-footer__sponsors container">
      <li
        v-for="sponsor in sponsors"
        :key="sponsor.icon"
        class="main-footer__sponsor"
      >
        <div class="main-footer__sponsor__logo">
          <svg class="main-footer__sponsor__logo__image" viewBox="0 0 190 150">
            <use :xlink:href="`/images/sponsors/${sponsor.logo}.svg#logo`" />
          </svg>
          <b class="visually-hidden">{{ sponsor.title }}</b>
        </div>
        <p class="main-footer__sponsor__description" v-html="sponsor.description" />
      </li>
    </ul>

    <div class="main-footer__wrapper">
      <div class="main-footer__container container">
        <div class="main-footer__secondary">
          <div class="main-footer__logo">
            <nuxt-link to="/" class="main-footer__logo__link" @click.native="handleLogoClick">
              <svg class="main-footer__logo__image" viewBox="0 0 70 56">
                <use xlink:href="/images/logo-emblem.svg#icon" />
              </svg>
            </nuxt-link>
          </div>
          <div class="main-footer__secondary-pages">
            <ul class="main-footer__secondary-pages__list">
              <li
                v-for="page in secondaryPages"
                :key="`footer-secondary-pages-${page.url}`"
                class="main-footer__secondary-pages__item"
              >
                <nuxt-link :to="page.url" class="main-footer__secondary-pages__link title title--caption link link--silent">
                  {{ page.title }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-footer__nav">
          <div class="main-footer__nav__inner main-footer__nav__col">
            <p class="main-footer__nav__title">
              Oldalak
            </p>
            <ul class="main-footer__nav__list">
              <li
                v-for="page in primaryPages"
                :key="`footer-primary-pages-${page.url}`"
                class="main-footer__nav__item"
              >
                <nuxt-link :to="page.url" class="main-footer__nav__link title title--caption link link--silent">
                  {{ page.title }}
                </nuxt-link>
              </li>
            </ul>
          </div>

          <div class="main-footer__nav__partner main-footer__nav__col">
            <p class="main-footer__nav__title">
              Kapcsolódó oldalak
            </p>
            <ul class="main-footer__nav__list">
              <li
                v-for="page in partnerSites"
                :key="`footer-partner-sites-${page.url}`"
                class="main-footer__nav__item"
              >
                <a :href="page.url" target="_blank" rel="noopener" class="main-footer__nav__link title title--caption link link--silent">
                  {{ page.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul class="main-footer__socials">
          <li v-for="social in socials" :key="social.title" class="main-footer__socials__item">
            <a :href="social.url" rel="noopener noreferrer nofollow" class="main-footer__socials__link">
              <SvgIcon :name="social.icon" size="l" class="main-footer__socials__link__icon" />
              <b class="visually-hidden">{{ social.title }}</b>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import smoothScrollTop from '../library/utils/smoothScrollTop'
import { PARTNER_SITES, FOOTER_PRIMARY_PAGES, FOOTER_SECONDARY_PAGES, SITE_SOCIALS, SITE_SPONSORS } from '../constants'

export default {

  data: () => ({
    partnerSites: [...PARTNER_SITES],
    primaryPages: [...FOOTER_PRIMARY_PAGES],
    secondaryPages: [...FOOTER_SECONDARY_PAGES],
    sponsors: [...SITE_SPONSORS],
    socials: [...SITE_SOCIALS]
  }),

  methods: {
    handleLogoClick () {
      smoothScrollTop()
    }
  }

}
</script>
