<script>
export default {

  abstract: true,

  inheritAttrs: false,

  props: {
    width: { type: [String, Number], default: null },
    height: { type: [String, Number], default: null },
    skeletonClass: { type: [String, Array, Object], default: null },
    isInline: { type: Boolean, default: false },
    isCircle: { type: Boolean, default: false },
    aspectRatio: { type: [String, Number], default: 1 },
    isLoading: { type: Boolean, default: true }
  },

  render (h) {
    if (this.isLoading) {
      return h(
        'div',
        {
          class: [
            'skeleton-loader',
            this.skeletonClass,
            this.width && 'skeleton-loader--has-width',
            this.height && 'skeleton-loader--has-height',
            this.aspectRatio && 'skeleton-loader--has-ratio',
            this.isInline ? 'skeleton-loader--inline' : 'skeleton-loader--block',
            this.isCircle && 'skeleton-loader--circle'
          ],
          style: {
            '--aspect-ratio': this.aspectRatio,
            '--width': this.width,
            '--height': this.height
          }
        },
        [
          h('div', { class: 'skeleton-loader__inner' })
        ]
      )
    }

    return this.$slots?.default
  }

}
</script>
