export const getMessage = key => messages.get(key) || key || ''

export const messages = new Map()
messages.set('success.modification', 'Sikeres módosítás!')
messages.set('file-upload.success', 'Sikeres fájlfeltöltés!')
messages.set('verification.success', 'Sikeres volt az e-mail cím megerősítése. Kérjük, jelentkezz be!')
messages.set('login.failed', 'A megadott e-mail cím vagy jelszó nem megfelelő.')
messages.set('login.failed.not-verified', 'Az e-mail cím nincs megerősítve.')
messages.set('login.failed.unknown-reason', 'Ismeretlen hiba történt a bejelentkezés során.')
messages.set('reset-password.email.not-sent', 'Nem sikerült elküldeni a megerősítő e-mailt.')
messages.set('password.reset.failed', 'Ismeretlen hiba történt.')
messages.set('password.rules.failed', 'A jelszó nem felel meg a szabályoknak.')
