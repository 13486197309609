<template>
  <div
    class="horizontal-scroller__scrollbar"
    :class="{
      'horizontal-scroller__scrollbar--hidden': !isScrollbarVisible,
      'horizontal-scroller__scrollbar--dragging': isDragging
    }"
    :style="styles"
    role="scrollbar"
    aria-orientation="horizontal"
    aria-valuemax="100"
    aria-valuemin="0"
    :aria-valuenow="scrollPositionPercent"
    tabindex="0"
  >
    <div ref="track" class="horizontal-scroller__scrollbar__track" @click.self="onTrackClick">
      <button
        type="button"
        class="horizontal-scroller__scrollbar__thumb"
        @mousedown.left="onMouseDown"
      />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    left: { type: Number, default: 0 },
    visibleWidth: { type: Number, default: 0 },
    fullWidth: { type: Number, default: 0 }
  },

  data: () => ({
    isDragging: false,
    dragStartX: null,
    dragStartLeft: null
  }),

  computed: {
    scrollBarSizePercent () {
      return Math.round(this.visibleWidth / this.fullWidth * 100 * 1000) / 1000
    },

    scrollBarOffsetPercent () {
      return Math.round(this.left / this.fullWidth * 100 * 1000) / 1000
    },

    scrollPositionPercent () {
      return Math.round(this.left / (this.fullWidth - this.visibleWidth) * 100 * 1000) / 1000
    },

    maxLeft () {
      return this.fullWidth - this.visibleWidth
    },

    styles () {
      return {
        '--scrollbar-size': `${this.scrollBarSizePercent}%`,
        '--scrollbar-offset': `${this.scrollBarOffsetPercent}%`
      }
    },

    isScrollbarVisible () {
      return this.visibleWidth !== this.fullWidth
    }
  },

  beforeDestroy () {
    this._onMouseUp()
    this.isDragging = false
  },

  methods: {
    onTrackClick (e) {
      const { x: trackX, width: trackWidth } = this.$refs.track.getBoundingClientRect()
      const { clientX: clickX } = e

      const newLeft = ((clickX - trackX) / trackWidth) * this.maxLeft
      this.$emit('update:left', this._clampLeft(newLeft))
    },

    onMouseDown (e) {
      e.preventDefault() // needed for FF, otherwise it would start the drag interaction

      this.dragStartX = e.pageX
      this.dragStartLeft = this.left

      window.addEventListener('mouseup', this._onMouseUp)
      window.addEventListener('mousemove', this._onMouseMove)
    },

    _onMouseMove (e) {
      e.preventDefault()

      const scalingRatio = this._getScalingRatio()
      const distance = (e.pageX - this.dragStartX) * scalingRatio

      this.isDragging = true
      this.$emit('update:left', this._clampLeft(this.dragStartLeft + distance))
    },

    _onMouseUp () {
      this.dragStartX = null
      this.dragStartLeft = null
      this.isDragging = false

      window.removeEventListener('mouseup', this._onMouseUp)
      window.removeEventListener('mousemove', this._onMouseMove)
    },

    _getScalingRatio () {
      return this.fullWidth / this.$refs.track?.clientWidth
    },

    _clampLeft (value) {
      return Math.min(Math.max(0, value), this.maxLeft)
    }
  }

}
</script>
