import { isDate } from '../../library/utils/date'

export default ({ req, route, $config }, inject) => {
  const formatDate = (date, opts) => {
    const { isCompact, year, month, day, hasTime } = opts || {}

    // Handle non-date values:
    if (!isDate(date)) {
      return date
    }

    // @TODO: handle ISO-looking, but not fully compliant/valid strings, eg. "2021-12-01 11:20"
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#parameters

    // Construct options:
    const options = { year: 'numeric', month: 'long', day: 'numeric' }

    if (isCompact) {
      options.month = '2-digit'
      options.day = '2-digit'
    }

    if (hasTime) {
      options.hour = '2-digit'
      options.minute = '2-digit'
    }

    if (year) { options.year = year === 'hidden' ? undefined : year }
    if (month) { options.month = month === 'hidden' ? undefined : month }
    if (day) { options.day = day === 'hidden' ? undefined : day }

    // Create the localized date string:
    return (new Date(date)).toLocaleDateString('hu-HU', options)
  }

  inject('formatDate', formatDate)
}
