import Imgproxy from 'imgproxy'

export default ({ req, route, $config }, inject) => {
  const imgproxy = new Imgproxy({
    baseUrl: $config.IMGPROXY_URL,
    key: $config.IMGPROXY_KEY,
    salt: $config.IMGPROXY_SALT,
    encode: true
  })

  const imgproxyFn = (url, { width, height, resizeMethod, isRetina, gravity }) => {
    if (!$config.IMGPROXY_URL) {
      return url
    }

    const urlBase = req ? `https://${req.headers.host}` : window.location.origin

    let res = imgproxy
      .builder()
      .resize(resizeMethod || 'fit', width || 0, height || 0, 0)
      .dpr(isRetina ? 2 : 1)

    if ((typeof gravity === 'object' && 'x' in gravity && 'y' in gravity)) {
      res = res.gravity({ x: gravity.x / 100, y: gravity.y / 100 })
    }

    return res.generateUrl((new URL(url, urlBase)).href)
  }

  inject('imgproxy', imgproxyFn)
}
