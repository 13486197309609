<template>
  <Transition name="fade">
    <div v-if="isVisible" class="reset-password-modal modal" :data-theme="modalTheme" @click="onClickOutside">
      <div class="reset-password-modal__wrapper modal__wrapper scrollable">
        <div v-loading="isLoading" class="reset-password-modal__window modal__window">
          <div class="reset-password-modal__inner modal__inner" @click.stop>
            <SvgIcon v-if="isFormSent" name="checkmark-2" size="xxxl" class="reset-password-modal__icon modal__icon" />

            <h3 class="reset-password-modal__title modal__title title title--3-light">
              {{ isFormSent ? 'Sikeres mentés' : 'Jelszó módosítása' }}
            </h3>

            <p v-if="isFormSent" class="reset-password-modal__subtitle modal__subtitle title title--5-light">
              Sikeresen elmentetted új jelszavadat. Most már be tudsz lépni.
            </p>

            <p v-if="isFormSent" class="reset-password-modal__cta modal__cta">
              <BaseButton size="large" class="modal__cta__button button--flush" @click="openLoginModal">
                Belépek
              </BaseButton>
            </p>

            <ValidationObserver v-else ref="resetPasswordForm" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(sendForm)">
                <div class="form">
                  <ValidationProvider
                    v-if="!form.token"
                    v-slot="{ errors, classes }"
                    vid="current_password"
                    name="Jelenlegi jelszó"
                    rules="required"
                    slim
                  >
                    <PasswordInput
                      v-model="form.current_password"
                      label="Jelenlegi jelszó"
                      autocomplete="current-password"
                      class="form__input"
                      :input-class="classes"
                      :errors="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors, classes }" vid="password" name="Új jelszó" rules="required" slim>
                    <PasswordInput
                      v-model="form.password"
                      label="Új jelszó"
                      autocomplete="new-password"
                      class="form__input"
                      :input-class="classes"
                      :errors="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors, classes }" vid="password_confirmation" name="Új jelszó megerősítése" rules="required|confirmed:password" slim>
                    <PasswordInput
                      v-model="form.password_confirmation"
                      label="Új jelszó megerősítése"
                      autocomplete="new-password"
                      class="form__input"
                      :input-class="classes"
                      :errors="errors"
                    />
                  </ValidationProvider>
                  <PasswordRules
                    v-model="form.password"
                    class="form__password-rules"
                    @rules-validity-update="handlePasswordRulesValidityUpdate"
                  />
                </div>
                <p class="reset-password-modal__cta modal__cta">
                  <BaseButton type="submit" size="large" class="modal__cta__button button--flush">
                    Küldés
                  </BaseButton>
                </p>
              </form>
            </ValidationObserver>
          </div>
          <button type="button" class="modal__close-button" @click.prevent="close">
            <SvgIcon name="x" size="m" class="modal__close-button__icon" />
          </button>
        </div>
      </div>

      <Portal to="curtains">
        <Transition name="fade">
          <div v-if="isVisible" class="curtain curtain--modal" />
        </Transition>
      </Portal>
    </div>
  </Transition>
</template>

<script>
import { getMessage } from '../utils/messages'
import ModalMixin from '../mixins/Modal'

export default {

  name: 'ResetPasswordModal',

  mixins: [ModalMixin],

  data: () => ({
    form: { current_password: '', password: '', password_confirmation: '', email: '', token: '' },
    isFormSent: false,
    isLoading: false
  }),

  mounted () {
    this._handlePreOpeningModal()
  },

  methods: {
    open (payload) {
      this.superOpen()
      this.form.email = payload?.email || ''
      this.form.token = payload?.token || ''
      this.form.current_password = ''
      this.form.password = ''
      this.form.password_confirmation = ''
      this.isFormSent = false
      this.isLoading = false
    },

    async sendForm () {
      this.isLoading = true

      try {
        await this.$axios.get('/crm/sanctum/csrf-cookie')
        await this.$axios.post('/crm/reset-password', this.form)

        if (this.$auth.loggedIn) {
          await this.$auth.logout()
          this.$router.push('/')
        }

        this.isFormSent = true

        this.$router.replace({
          query: {
            ...this.$route?.query,
            showPasswordResetModal: undefined,
            email: undefined,
            token: undefined
          }
        })
      } catch (error) {
        this.$refs.resetPasswordForm.setErrors(error?.response?.data?.errors || {})
      }

      this.isLoading = false
    },

    handlePasswordRulesValidityUpdate (value) {
      if (!this.form.password || value) {
        this.$refs?.resetPasswordForm?.setErrors({ password: null })
        return
      }

      setTimeout(() => {
        this.$refs?.resetPasswordForm?.setErrors({ password: [getMessage('password.rules.failed')] })
      }, 1)
    },

    _handlePreOpeningModal () {
      if (this.$route?.query?.showPasswordResetModal === 'true') {
        this.open({
          email: this.$route?.query?.email || '',
          token: this.$route?.query?.token || ''
        })
      }
    },

    openLoginModal () {
      this.$bus.$emit('LOGIN_MODAL.OPEN', { email: this.form?.email })
    }
  }

}
</script>
