const validate = (value, { length }) => {
  if (!value || !length) {
    return true
  }

  if (typeof value === 'number') {
    value = String(value)
  }

  if (!value.length) {
    return true
  }

  return value.length <= length
}

const params = [
  {
    name: 'length',
    cast: value => Number(value)
  }
]

export { validate, params }

export default { validate, params }
